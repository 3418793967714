<!-- =========================================================================================
	File Name: ECommerceCheckout.vue
	Description: eCommerce Checkout page
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="ecommerce-checkout-demo">
        <form-wizard
            id="checkoutWizard"
            ref="checkoutWizard"
            color="rgba(var(--vs-primary), 1)"
            :title="null"
            :subtitle="null"
            :hide-buttons="true">

            <!-- tab 1 content -->
            <tab-content title="Sepet" icon="feather icon-shopping-cart" class="mb-5">

                <!-- IF CART HAVE ITEMS -->
                <div class="vx-row" v-if="cartItems.length">

                    <!-- LEFT COL -->
                    <div class="vx-col lg:w-2/3 w-full relative">
                        <div class="items-list-view" v-for="(item, index) in cartItems" :key="item.nStokID">
                            <item-list-view :item="item">

                                <!-- SLOT: ITEM META -->
                                <template slot="item-meta">
                                    <h6 class="item-name font-semibold mb-1">{{ item.sAciklama }}</h6>
                                    <p class="text-sm mb-2 cursor-pointer"><span class="text-primary font-semibold">{{ item.sMarka }}</span></p>
                                    <p class="text-success text-sm">Mevcut</p>

                                    <p class="mt-4 font-bold text-sm">Miktar</p>
                                    <vs-input-number min="1" max="10" :value="item.quantity" @input="updateItemQuantity($event, index)" class="inline-flex" />

                                    <!-- <p class="font-medium text-grey mt-4">Teslimat, {{ item.delivery_date }}</p>
                                    <p class="text-success font-medium">{{ item.discount_in_percentage }}% off {{ item.offers_count }} offers Available</p> -->
                                </template>

                                <!-- SLOT: ACTION BUTTONS -->
                                <template slot="action-buttons">

                                    <!-- PRIMARY BUTTON: REMOVE -->
                                    <div class="item-view-primary-action-btn p-3 rounded-lg flex flex-grow items-center justify-center cursor-pointer mb-3" @click="removeItemFromCart(item)">
                                        <feather-icon icon="XIcon" svgClasses="h-4 w-4" />
                                        <span class="text-sm font-semibold ml-2">KALDIR</span>
                                    </div>

                                    <!-- SECONDARY BUTTON: MOVE-TO/VIEW-IN WISHLIST -->
                                    <div class="item-view-secondary-action-btn bg-primary p-3 rounded-lg flex flex-grow items-center justify-center text-white cursor-pointer" @click="wishListButtonClicked(item)">
                                        <feather-icon icon="HeartIcon" :svgClasses="[{'text-white fill-current': isInWishList(item.nStokID)}, 'h-4 w-4']" />
                                        <span class="text-sm font-semibold ml-2" v-if="isInWishList(item.nStokID)">Favori</span>
                                        <span class="text-sm font-semibold ml-2" v-else>Favori</span>
                                    </div>
                                </template>
                            </item-list-view>
                        </div>
                    </div>

                    <!-- RIGHT COL -->
                    <div class="vx-col lg:w-1/3 w-full">
                        <vx-card>
                            <!-- <p class="text-grey mb-3">Opsiyon</p>
                            <div class="flex justify-between">
                                <span class="font-semibold">Kuponlar</span>
                                <span class="font-medium text-primary cursor-pointer">Uygula</span>
                            </div> -->

                            <!-- <vs-divider /> -->

                            <p class="font-semibold mb-3">Ücret Detayları</p>
                            <div class="flex justify-between mb-2">
                                <span class="text-grey">Toplam</span>
                                <span>{{getTradeResult.lMalBedeli | currency}}</span>
                            </div>
                            <div class="flex justify-between mb-2">
                                <span class="text-grey">İskonto</span>
                                <span class="text-success">{{getTradeResult.lMalIskontoTutari}}</span>
                            </div>
                            <div class="flex justify-between mb-2">
                                <span class="text-grey">Kdv</span>
                                <span>{{ getTradeResult.lToplamKdv | currency}}</span>
                            </div>
                            <!-- <div class="flex justify-between mb-2">
                                <span class="text-grey">Ek Masraflar</span>
                                <a href="#" class="text-primary">Detaylar</a>
                            </div>
                            <div class="flex justify-between mb-2">
                                <span class="text-grey">Teslimat Ücretleri</span>
                                <span class="text-success">Ücretsiz</span>
                            </div> -->

                            <vs-divider />

                            <div class="flex justify-between font-semibold mb-3">
                                <span>Toplam</span>
                                <span>{{ getTradeResult.lNetTutar | currency}}</span>
                            </div>

                            <vs-button class="w-full" @click="$refs.checkoutWizard.nextTab()">Tamamla</vs-button>
                        </vx-card>
                    </div>
                </div>

                <!-- IF NO ITEMS IN CART -->
                <vx-card title="Sepetinizde Ürün Bulunamadı..!" v-else>
                    <vs-button @click="$router.push('/shop')">Mağazaya Git</vs-button>
                </vx-card>

            </tab-content>

            <!-- tab 2 content -->
            <tab-content title="Adres" icon="feather icon-home" class="mb-5">

                <div class="vx-row">

                    

                    <!-- RIGHT COL: CONTINUE WITH SAVED ADDRESS -->
                    <!-- <div class="vx-col lg:w-1/3 w-full">
                        <vx-card title="Ömer Faruk DEMİRYÜREK">
                          

                            <vs-divider />

                            <vs-button class="w-full" @click="$refs.checkoutWizard.nextTab()">Bu Adrese Gönder</vs-button>
                        </vx-card>
                    </div> -->
                    <div class="vx-col lg:w-3/5 w-full">
              <vx-card :title="$t('faturaadresi')">
                <vs-divider />
                <form data-vv-scope="add-new-sevkiyat">
                  <div class="vx-row" >
                    <div class="vx-col sm:w-2/2 w-full" v-show="getTradeResult.bFirma">
                      <DxLookup
                        :data-source="tbFirma"
                        :show-popup-title="false"
                        display-expr="sAciklama"
                        value-expr="nFirmaID"
                        :value="sevkiyat.nFirmaID"
                        @value-changed="setSelectedValue"
                        item-template="field-item"
                        field-template="field-item"
                      >
                      <template #field-item="{ data: item }">
                          <div>
                            <div style="width:80px;float:left;">{{item.sKodu}}</div>
                            <div style="width:200px;float:left;overflow:hidden;text-overflow: ellipsis">{{item.sAciklama}}</div>
                            <div style="width:60px;overflow:hidden;text-overflow: ellipsis">{{item.sIl}}</div>
                            
                          </div>
                      </template>
                      </DxLookup>
                    </div>

                    <div class="vx-col sm:w-2/2 w-full">
                      <!-- <vs-input
                                            
                                            data-vv-as="field"
                                            name="sevkiyat.sFirmaAdi"
                                            :label="$t('firmaadi')"
                                            v-model="sevkiyat.sFirmaAdi"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('firmaadi')}}
                        <DxTextBox :value.sync="sevkiyat.sFirmaAdi" :disabled="disabled" :max-length="60" />
                      </div>

                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sFirmaAdi')" class="text-danger" >{{ errors.first('add-new-sevkiyat.sevkiyat.sFirmaAdi') }}</span> -->
                    </div>
                    <div class="vx-col sm:w-2/2 w-full">
                      <!-- <vs-input
                                            
                                            data-vv-as="field"
                                            name="sevkiyat.sAdres1"
                                            :label="$t('adres')"
                                            v-model="sevkiyat.sAdres1"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('adres')}}
                        <DxTextBox :value.sync="sevkiyat.sAdres1" :disabled="disabled" :max-length="60" />
                      </div>
                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sAdres1')" class="text-danger" >{{ errors.first('add-new-sevkiyat.sevkiyat.sAdres1') }}</span> -->
                    </div>
                    <div class="vx-col sm:w-2/2 w-full">
                      <!-- <vs-input
                                            data-vv-as="field"
                                            name="sevkiyat.Adres2"
                                            v-model="sevkiyat.sAdres2"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->

                      <div class="w-full mt-5">
                        <DxTextBox :value.sync="sevkiyat.sAdres2" :disabled="disabled" :max-length="60" />
                      </div>
                    </div>
                  </div>

                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full">
                      <!-- <vs-input
                                            
                                            name="sevkiyat.sSemt"
                                            :label="$t('sehir')"
                                            v-model="sevkiyat.sSemt"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('sehir')}}
                        <DxTextBox :value.sync="sevkiyat.sSemt" :disabled="disabled" :max-length="60" />
                      </div>
                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sSemt')" class="text-danger">{{ errors.first('add-new-sevkiyat.sevkiyat.sSemt') }}</span> -->
                    </div>

                    <div class="vx-col sm:w-1/2 w-full">
                      <!-- <vs-input
                                            
                                            name="sevkiyat.sIl"
                                            :label="$t('il')"
                                            v-model="sevkiyat.sIl"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('il')}}
                        <DxLookup
                          :data-source="tbIl"
                          :close-on-outside-click="true"
                          :show-popup-title="false"
                          display-expr="il"
                          value-expr="il"
                          :value.sync="sevkiyat.sIl"
                          :disabled="disabled"
                        ></DxLookup>
                      </div>
                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sIl')" class="text-danger">{{ errors.first('add-new-sevkiyat.sevkiyat.sIl') }}</span> -->
                    </div>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full">
                      <!-- <vs-input
                                            
                                            name="sevkiyat.sVergiDairesi"
                                            :label="$t('vergidairesi')"
                                            v-model="sevkiyat.sVergiDairesi"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('vergidairesi')}}
                        <DxTextBox :value.sync="sevkiyat.sVergiDairesi" :disabled="disabled" :max-length="60" />
                      </div>
                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sVergiDairesi')" class="text-danger" >{{ errors.first('add-new-sevkiyat.sevkiyat.sVergiDairesi') }}</span> -->
                    </div>

                    <div class="vx-col sm:w-1/2 w-full">
                      <!-- <vs-input
                                            
                                            name="sevkiyat.sVergiNo"
                                            :label="$t('vkn')"
                                            v-model="sevkiyat.sVergiNo"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('vkn')}}
                        <DxTextBox :value.sync="sevkiyat.sVergiNo" :disabled="disabled" :max-length="60" />
                      </div>
                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sVergiNo')" >{{ errors.first('add-new-sevkiyat.sevkiyat.sVergiNo') }}</span> -->
                    </div>
                  </div>

                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full">
                      <!-- <vs-input
                                            
                                            name="sevkiyat.sMail"
                                            :label="$t('email')"
                                            v-model="sevkiyat.sMail"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('email')}}
                        <DxTextBox :value.sync="sevkiyat.sMail" :disabled="disabled" :max-length="60" />
                      </div>

                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sMail')" class="text-danger" >{{ errors.first('add-new-sevkiyat.sevkiyat.sMail') }}</span> -->
                    </div>
                    <div class="vx-col sm:w-1/2 w-full">
                      <!-- <vs-input
                                            v-validate="'required|digits:10'"
                                            name="sevkiyat.sTelefon"
                                            :label="$t('telefon')"
                                            v-model="sevkiyat.sTelefon"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('telefon')}}
                        <DxTextBox :value.sync="sevkiyat.sTelefon" :disabled="disabled" :max-length="60" />
                      </div>
                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sTelefon')" class="text-danger" >{{ errors.first('add-new-sevkiyat.sevkiyat.sTelefon') }}</span> -->
                    </div>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full">
                      <!-- <vs-input
                                            
                                            name="sevkiyat.sYetkili"
                                            :label="$t('yetkili')"
                                            v-model="sevkiyat.sYetkili"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('yetkili')}}
                        <DxTextBox :value.sync="sevkiyat.sYetkili" :disabled="disabled" :max-length="60" />
                      </div>
                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sYetkili')" class="text-danger">{{ errors.first('add-new-sevkiyat.sevkiyat.sYetkili') }}</span> -->
                    </div>

                    <div class="vx-col sm:w-1/2 w-full">
                      <!-- <vs-input
                                            
                                            name="sevkiyat.sSektor"
                                            :label="$t('sektor')"
                                            v-model="sevkiyat.sSektor"
                                            class="w-full mt-5"
                                            :disabled="disabled"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('sektor')}}
                        <!-- <DxTextBox :value.sync="sevkiyat.sSektor" :disabled="disabled" /> -->
                        <DxLookup
                        :data-source="tbFSinif3"
                        :close-on-outside-click="true"
                        :show-popup-title="false"
                        display-expr="sAciklama"
                        value-expr="sSinifKodu"
                        :value.sync="sevkiyat.sSektor"
                        :disabled="disabled"
                        
                      ></DxLookup>
                      </div>
                      <!-- <span v-show="errors.has('add-new-sevkiyat.sevkiyat.sSektor')" class="text-danger">{{ errors.first('add-new-sevkiyat.sevkiyat.sSektor') }}</span> -->
                    </div>
                    <div class="vx-col sm:w-2/2 w-full">
                      <!-- <vs-input
                                            
                                            data-vv-as="field"
                                            :label="$t('notlar')"
                                            name="sevkiyat.sNot"
                                            v-model="sevkiyat.sNot"
                                            class="w-full mt-5"
                      />-->
                      <div class="w-full mt-5">
                        {{$t('notlar')}}
                        <DxTextArea :value.sync="sevkiyat.sNot" />
                      </div>
                    </div>

                    
                  </div>
                </form>
                <br />
                <vs-button
                  class="w-full bg-primary"
                  @click="scrollBehavior('odeme')"
                >{{$t('devam')}}</vs-button>
              </vx-card>
            </div>

                </div>
            </tab-content>

            <!-- tab 3 content -->
            <tab-content :title="$t('odeme')" icon="feather icon-credit-card" class="mb-5">
          <div class="vx-row" id ="odeme" ref="#odeme">
            <!-- LEFT COL: PAYMENT OPTIONS -->
            <div class="vx-col lg:w-2/3 w-full">
              <vx-card :title="$t('odemeopsiyonlari')" subtitle class="mb-base">
                <div class="mt-3">
                  <ul>
                    <!-- OPTION 1 -->
                    <li>
                      <!-- CARD DETAILS -->
                      <div class="flex flex-wrap justify-between items-center">
                        <vs-radio v-model="paymentMethod" vs-value="debit-card" @change="scrollBehavior('odeme')">
                          <div class="flex items-center">
                            <span>{{$t('kredikarti')}}</span>
                          </div>
                        </vs-radio>
                        
                      </div>
                      <div class="wrapper" v-show="paymentMethod ==='debit-card'">
                        <iframe src="https://www.paytr.com/odeme/guvenli/" visible="true" runat="server" id="paytriframe" frameborder="0" scrolling="no" style="width: 100%;"></iframe>
                        <vscript script='<script>iFrameResize({}, "#paytriframe");</script>'></vscript> 
                        <!-- <CardForm
                          
                          :form-data="formData"
                          @input-card-number="updateCardNumber"
                          @input-card-name="updateCardName"
                          @input-card-month="updateCardMonth"
                          @input-card-year="updateCardYear"
                          @input-card-cvv="updateCardCvv"
                        /> -->
                        <!-- backgroundImage="https://images.unsplash.com/photo-1572336183013-960c3e1a0b54?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80" -->
                      </div>

                      <!-- CVV BLOCK -->
                      <form data-vv-scope="cvv-form" v-show="paymentMethod ==='card'">
                        <div class="vx-col sm:w-2/2 w-full">
                          <vs-input
                            data-vv-as="field"
                            :label="$t('kartsahibi')"
                            name="sKartSahibi"
                            v-model="sKartSahibi"
                            class="w-full mt-5"
                          />
                        </div>
                        <div class="vx-col sm:w-2/2 w-full">
                          <vs-input
                            data-vv-as="field"
                            :label="$t('kartno')"
                            name="sKartNo"
                            v-model="sKartNo"
                            class="w-full mt-5"
                          />
                        </div>
                        <div class="vx-row">
                          <div class="vx-col sm:w-1/2 w-full">
                            <vs-input
                              
                              name="sAy"
                              :label="$t('ay')"
                              v-model="sAy"
                              class="w-full mt-5"
                            />
                            <!-- <span v-show="errors.has('cvv-form.sAy')" class="text-danger">{{ errors.first('cvv-form.sAy') }}</span> -->
                          </div>
                          <div class="vx-col sm:w-1/2 w-full">
                            <vs-input
                              
                              name="sYil"
                              :label="$t('yil')"
                              v-model="sYil"
                              class="w-full mt-5"
                            />
                            <!-- <span v-show="errors.has('cvv-form.sYil')" class="text-danger">{{ errors.first('cvv-form.sYil') }}</span> -->
                          </div>
                        </div>
                        <div class="vx-row">
                          <div class="vx-col sm:w-1/2 w-full">
                            <vs-input
                              
                              name="cvv"
                              label="CVV:*"
                              v-model="cvv"
                              class="w-full mt-5"
                            />
                            <!-- <span v-show="errors.has('cvv-form.cvv')" class="text-danger">{{ errors.first('cvv-form.cvv') }}</span> -->
                          </div>
                          <div class="vx-col sm:w-1/2 w-full">
                            <vs-input
                              
                              name="nTaksit"
                              :label="$t('taksit')"
                              v-model="nTaksit"
                              class="w-full mt-5"
                            />
                            <!-- <span v-show="errors.has('cvv-form.nTaksit')" class="text-danger">{{ errors.first('cvv-form.nTaksit') }}</span> -->
                          </div>
                        </div>
                      </form>
                    </li>

                    <vs-divider class="my-6" />

                    <!-- OPTION 3 -->
                    <li class="mt-2">
                      <vs-radio v-model="paymentMethod" vs-value="netBanking" @change="scrollBehavior('odeme')">{{$t('havale')}}</vs-radio>
                      <form data-vv-scope="eft-form" v-show="paymentMethod ==='netBanking'">
                        <div class="vx-col sm:w-1/1 w-full">
                          <vs-input
                            data-vv-as="field"
                            :label="$t('aciklama')"
                            name="sHavaleNo"
                            v-model="sHavaleNo"
                            class="w-full mt-5"
                          />
                        </div>
                      </form>
                    </li>

                    <!-- OPTION 5 -->
                    <li class="mt-2">
                      <vs-radio
                        v-model="paymentMethod"
                        vs-value="cashOnDelivery" @change="scrollBehavior('odeme')"
                      >{{$t('kapidaodeme')}}</vs-radio>
                      <form data-vv-scope="payment-form" v-show="paymentMethod ==='cashOnDelivery'">
                        <div class="vx-col sm:w-1/1 w-full">
                          <vs-select :label="$t('odeme')" v-model="paymentType" class="w-full mt-5">
                            <vs-select-item
                              :key="index"
                              :value="item.value"
                              :text="$t(item.text)"
                              v-for="(item,index) in paymentTypeOptions"
                            />
                          </vs-select>
                        </div>
                      </form>
                    </li>
                  </ul>

                  <vs-divider />

                  <!-- GIFT CARD -->
                  <!-- <div class="inline-flex items-center cursor-pointer">
                                    <feather-icon icon="PlusSquareIcon" class="mr-2" svgClasses="h-6 w-6" />
                                    <span>Add Gift Card</span>
                  </div>-->
                  <DxLoadPanel :visible.sync="loadingVisible" position="center" :close-on-outside-click="true">
                  </DxLoadPanel>

                  <vs-button
                    class="w-full bg-primary"
                    @click.prevent="makePayment"
                    :disabled="bDisabled"
                  >{{$t('bitir')}}</vs-button>
                </div>
              </vx-card>
            </div>

            <!-- RIGHT COL: PRICE -->
            <div class="vx-col lg:w-1/3 w-full">
              <vx-card :title="$t('toplam')">
                <div class="flex justify-between mb-2">
                  <span>{{getTradeResult.lToplamMiktar}} {{$t('adet')}}</span>
                  <span class="font-semibold">{{getTradeResult.lNetTutar | currency}}</span>
                </div>
                <div class="flex justify-between mb-2">
                  <span>{{$t('kargoucreti')}}</span>
                  <span class="text-success">{{$t('ucretsiz')}}</span>
                </div>

                <vs-divider />

                <div class="flex justify-between">
                  <span>{{$t('yekun')}}</span>
                  <span class="font-semibold">{{getTradeResult.lNetTutar | currency}}</span>
                </div>
              </vx-card>
            </div>
          </div>
        </tab-content>

        </form-wizard>
    </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
const ItemListView = () => import('./components/ItemListView.vue')
import { mapGetters } from "vuex";

import { DxColumn, DxDataGrid, DxSelection,DxFilterRow } from "devextreme-vue/data-grid";
import { DxLookup, DxSelectBox, DxTextBox, DxTextArea } from "devextreme-vue";
import DataSource from "devextreme/data/data_source";
import Query from 'devextreme/data/query';

import { ililce } from "../../../ililce.js";
import router from '../../../router.js'
import {mobilit} from '../../../utils/sharedconfig.js'
import xml2js from "xml2js";
import fs from "fs";
import vscript from 'vue-script-component'

// const tbFirma = new DataSource({
//   store: mobilit.tbFirma,
//   select: [
//     "nFirmaID",
//     "sKodu",
//     "sAciklama",
//     "sAdres1",
//     "sAdres2",
//     "sVergiDairesi",
//     "sVergiNo",
//     "sSemt",
//     "sIl"
//   ],
//   filter: [["bAktif", "=", true]],
//   searchExpr: ["sAciklama"],
//   sort: { field: "sAciklama", desc: false }
// });
const tbFirma = [{nFirmaID:0,sKodu:'',sAciklama:'',sAdres1:'',sAdres2:'',sVergiDairesi:'',sVergiNo:'',sSemt:'',sIl:''}]
const tbFirmaIletisimi = [{nIletisimID:0,nFirmaID:0,sIletisimAraci:'',sIletisimAdresi:''}]
const tbFSinif3 =[{sSinifKodu:'001',sAciklama:'Genel'}]
const tbFirmaLisans = [{sOnayKodu:''}]
// const tbFirmaIletisimi = new DataSource({
//   store: mobilit.tbFirmaIletisimi,
//   filter: [""]
// });
// const tbFSinif3 = new DataSource({
//   store: mobilit.tbFSinif3
// });
// const tbFirmaLisans = new DataSource({
//   store: mobilit.tbFirmaLisans,
//   select: ["sOnayKodu", "dteGecerlilikTarihi", "sBilgisayar"],
//   filter: ["nFirmaID", "=", 0]
// });

function getEmployeeNames(selectedRowsData) {
  const getEmployeeName = row => `${row.sOnayKodu}`;

  return selectedRowsData.length
    ? selectedRowsData.map(getEmployeeName).join(", ")
    : "";
}

//import CardForm from "@/pages/components/CardForm";
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256'
import PayTR from 'node-paytr';

import qs from 'qs';
export default {
    data() {
        return {

            // TAB 2
            sIp:'0.0.0.0',
            lDovizKuru:7,
      loadingVisible: false,
      disabled: false,
      bDisabled: false,
      prefix: "",
      selectedEmployeeNames: "",
      selectedRowKeys: [],
      selectionChangedBySelectBox: false,
      bLisanslar: false,
      tbIl: ililce,
      sMail:"",
      sSatir:"",
      sFirma:"",
      sModuller:"",
      sOnayKodlari:"",
      formData: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: ""
      },
      tbfirma:{sKodu:"",sUstKodu:"",sAciklama:"Mobil",nOzelIskontosu:0,lKrediLimiti:0,dteMutabakatTarihi: new Date(1900,1,1),nVadeGun:0,sHitap:"",sAdres1:"",sAdres2:"",sSemt:"",sIl:"",sUlke:"",sPostaKodu:"",sVergiDairesi:"",sVergiNo:"",nHesapID:0,sOzelNot:"",sKullaniciAdi:"web",dteKayitTarihi:new Date(),sFiyatTipi:"",sSaticiRumuzu:"",nOzelIskontosu2:0,nOzelIskontosu3:0,nOzelIskontosu4:0,bAktif:true,sDovizCinsi:"",bSatisYapilamaz:false,bTahsilatYapilamaz:false,nEnlem:0,nBoylam:0,nZiyaret:"",nPeriyod:0,sDepo:"",sEfaturaTipi:""},
      tbhesapplani:{sMasrafYontemi:"",sKodu:"",sUstKodu:"",sAciklama:"",sAlterneAciklama:"",lBilancoSatiri:0,sAktifPasif:"",lKarZararSatiri:0,sKarZararGelirGider:"",bMiktarVar:false,sKullaniciAdi:"mobil",dteKayitTarihi: new Date()},
      tbhesapsinifi:{nHesapID:0,sSinifKodu1:"",sSinifKodu2:"",sSinifKodu3:"",sSinifKodu4:"",sSinifKodu5:""},
      tbfirmasinifi:{nFirmaID:0,sSinifKodu1:"",sSinifKodu2:"",sSinifKodu3:"",sSinifKodu4:"",sSinifKodu5:""},
      tbfirmailetisimi:{nFirmaID:0,sIletisimAraci:"",sIletisimAdresi:""},
      tbfirmaaciklamasi:{nFirmaID:0,sAciklama1:"",sAciklama2:"",sAciklama3:"",sAciklama4:"",sAciklama5:""},
      tbstokfisimaster:{sFisTipi:"FS",dteFisTarihi: new Date(),nGirisCikis:3,lFisNo:0,nFirmaID:0,sDepo:"D001",dteValorTarihi: new Date(),bPesinmi:false,bListelendimi:false,bHizmetFaturasimi:false,lToplamMiktar:0.000,lMalBedeli:0.00,lMalIskontoTutari:0.00,nDipIskontoYuzdesi1:0.00,lDipIskontoTutari1:0.00,nDipIskontoYuzdesi2:0.00,lDipIskontoTutari2:0.00,lDipIskontoTutari3:0.00,lEkmaliyet1:0.00,lEkmaliyet2:0.00,lEkmaliyet3:0.00,nKdvOrani1:0,lKdvMatrahi1:0,lKdv1:0,nKdvOrani2:0,lKdvMatrahi2:0,lKdv2:0,nKdvOrani3:0,lKdvMatrahi3:0,lKdv3:0,nKdvOrani4:0,lKdvMatrahi4:0,lKdv4:0,nKdvOrani5:0,lKdvMatrahi5:0,lKdv5:0,lNetTutar:0.00,nTevkifatKdvOrani:0,lTevkifatKdvMatrahi:0,lTevkifatKdv:0,sCariIslem:"FS",nCariHareketID:0,sHareketTipi:"",bMuhasebeyeIslendimi:false,bFisTamamlandimi:false,lTransferFisiID:0,sTransferDepo:"",bFaturayaDonustumu:false,sKullaniciAdi:"mobil",dteKayitTarihi:new Date(),sYaziIle:"",nOTVOrani1:0,lOTVMatrahi1:0,lOTV1:0,nOTVOrani2:0,lOTVMatrahi2:0,lOTV2:0,bKilitli:false,bEfatura:false,sEfaturaTipi:"",sEfaturaGuid:"",nEfaturaDurum:0},
      tbstokfisiaciklamasi:{nStokFisiID:0,sAciklama1:"",sAciklama2:"",sAciklama3:"",sAciklama4:"",sAciklama5:""},
      tbstokfisiodemeplani:{sCariIslem:"FS",nStokFisiID:0,dteVadeTarihi: new Date(),lTutari:0.00,nCariHareketID:0,sDovizCinsi:"",lDovizMiktari:0.00,lDovizKuru:0.00},
      tbfirmahareketi:{sCariIslem:"FS",sDovizCinsi1:"",sDovizCinsi2:"",nFirmaID:0,sHangiUygulama:"FS",sHareketTipi:"",dteIslemTarihi: new Date(),dteValorTarihi: new Date(),sAciklama:"",lBorcTutar:0.00,lAlacakTutar:0.00,sEvrakNo:"",lVadeFarki:0,sKullaniciAdi:"mobil",dteKayitTarihi:new Date(),lDovizMiktari1:0.00,lDovizKuru1:0.00,lDovizMiktari2:0.00,lDovizKuru2:0.00,sSonKullaniciAdi:"mobil",dteSonUpdateTarihi:new Date(),sAciklama1:"",bVar:false},
      tbstokfisipesinadre:{nStokFisiID:0,sAciklama:"",sAdres1:"",sAdres2:"",sSemt:"",sIl:"",sUlke:"",sPostaKodu:"",sVergiDairesi:"",sVergiNo:"",sSubeMagaza:"",sTelefon1:"",sTelefon2:"",sTelefon3:"",sFaks:"",sMail:"",sAracSasiNo:"",sAracMotorNo:"",sAracMarka:"",sAracModel:"",sAracRenk:"",sSaticiRumuzu:""},
      tbstokfisidetayi:{nStokID:0,dteIslemTarihi:new Date(),nMusteriID:0,nFirmaID:0,sFisTipi:"FS",dteFisTarihi: new Date(),lFisNo:0,nGirisCikis:3,sDepo:"D001",lReyonFisNo:0,sStokIslem:"FS",sKasiyerRumuzu:"",sSaticiRumuzu:"",sOdemeKodu:"",dteIrsaliyeTarihi:new Date(),lIrsaliyeNo:0,lGirisMiktar1:0,lGirisMiktar2:0,lGirisFiyat:0.00,lGirisTutar:0.00,lCikisMiktar1:0,lCikisMiktar2:0,lCikisFiyat:0.00,lCikisTutar:0.00,sFiyatTipi:"1",lBrutFiyat:0.00,lBrutTutar:0.00,lMaliyetFiyat:0.00,lMaliyetTutar:0.00,lIlaveMaliyetTutar:0.00,nIskontoYuzdesi:0,lIskontoTutari:0.00,sDovizCinsi:"",lDovizFiyat:0.00,nReceteNo:0,nKdvOrani:0,nHesapID:0,sAciklama:"",sHareketTipi:"",bMuhasebeyeIslendimi:false,sKullaniciAdi:"mobil",dteKayitTarihi: new Date(),sDovizCinsi1:"",lDovizMiktari1:0,lDovizKuru1:0,sDovizCinsi2:"",lDovizMiktari2:0,lDovizKuru2:0,nOTVOrani:0,nStokFisiID:0,sMasrafYontemi:"",sHangiUygulama:"FS",nEkSaha1:0,nEkSaha2:0,bEkSoru1:false,bEkSoru2:false,sSonKullaniciAdi:"mobil",dteSonUpdateTarihi: new Date(),sBirimCinsi:"",lBirimMiktar:1,nPrim:0,lPrimTutari:0},
      tbstok:{sKodu:"",sAciklama:"",sKisaAdi:"",nStokTipi:0,sBedenTipi:"",sKavalaTipi:"",sRenk:"",sBeden:"",sKavala:"",sBirimCinsi1:"",sBirimCinsi2:"",nIskontoYuzdesi:0,sKdvTipi:"",nTeminSuresi:0,lAsgariMiktar:0,lAzamiMiktar:0,sOzelNot:"",sModel:"",sKullaniciAdi:"mobil",dteKayitTarihi:new Date(),bEksiyeDusulebilirmi:true,sDefaultAsortiTipi:"",bEksideUyarsinmi:false,bOTVVar:false,sOTVTipi:"",nIskontoYuzdesiAV:0,bEk1:false,nEk2:0,nPrim:0,nEn:0,nBoy:0,nYukseklik:0,nHacim:0,nAgirlik:0,sDovizCinsi:"",sAlisKdvTipi:"",nButce:0,nKarlilik:0,sUlke:""},
      tbstoksinifi:{nStokID:0,sSinifKodu1:"",sSinifKodu2:"",sSinifKodu3:"",sSinifKodu4:"",sSinifKodu5:"",sSinifKodu6:"",sSinifKodu7:"",sSinifKodu8:"",sSinifKodu9:"",sSinifKodu10:"",sSinifKodu11:"",sSinifKodu12:"",sSinifKodu13:"",sSinifKodu14:"",sSinifKodu15:""},
      tbstokaciklama:{nStokID:0,sAciklama1:"",sAciklama2:"",sAciklama3:"",sAciklama4:"",sAciklama5:"",sAciklama6:"",sAciklama7:"",sAciklama8:"",sAciklama9:"",sAciklama10:""},
      Modul:{
        nFirmaID:0,
        sModul:'',
        sPaket:'',
        sAciklama:'',
        sVersion:'18.2x',
        dteTarih: new Date(),
        dteKayitTarihi: new Date(),
      },
      Lisans:{
        sOnayKodu : '',
        nFirmaID : 0,
        nBayiID : 0,
        sParametre1 : '',
        sParametre2 : '',
        sManufactor : '',
        sModel : '',
        sSystemType : '',
        sCpuID : '',
        sBiosVersion : '',
        sHddSerial : '',
        sMacID : '',
        sBilgisayar : '',
        sOturum : '',
        sOS : '',
        sVer : '',
        sIP : '',
        sUlke : '',
        sBolge : '',
        sSifreyiAlan : '',
        dteGecerlilikTarihi : new Date(),
        dteKayitTarihi : new Date(),
        dteSonUpdateTarihi : new Date()
      },
      fatura: 
        {
          sYetkili: "Recep Atabay",
          sFirmaKodu: "320.34.01",
          sFirmaAdi: "Barkod Yazılım",
          sAdres1: "Perpa Ticaret Merkezi B Blok Kat:9 No:1454",
          sAdres2: "Okmeydanı",
          sSemt: "Şişli",
          sIl: "İstanbul",
          sVergiDairesi: "Şişli",
          sVergiNo: "1420592791",
          sMail: "recepatabay@gmail.com",
          sTelefon: "5322412008"
        }
      ,
      sevkiyat: 
        {
          nFirmaID: 0,
          sYetkili: "",
          sFirmaKodu: "",
          sFirmaAdi: "",
          sAdres1: "",
          sAdres2: "",
          sSemt: "",
          sIl: "Ankara",
          sVergiDairesi: "",
          sVergiNo: "",
          sMail: "",
          sTelefon: "",
          sSektor: "001",
          sNot: ""
        }
      ,
      fullName: "",
      mobileNum: "",
      pincode: "",
      houseNum: "",
      area: "",
      landmark: "",
      city: "",
      state: "",
      sHavaleNo: "",
      addressType: 1,
      paymentType: 1,
      scrolled:false,
      addressTypeOptions: [
        { text: "Home", value: 1 },
        { text: "Office", value: 2 }
      ],
      paymentTypeOptions: [
        { text: "nakit", value: 1 },
        { text: "kredikarti", value: 2 }
      ],
      tbFirma,
      tbFirmaIletisimi,
      tbFirmaLisans,
      tbFSinif3,
      
      // TAB 3
      paymentMethod: "cashOnDelivery",
      cvv: "",
      sKartNo: "",
      sKartSahibi: "",
      sAy: new Date().getMonth(),
      sYil: new Date().getFullYear(),
      nTaksit: 0
        }
    },
    mounted(){
       axios('https://api.ipify.org?format=json').then(response => {
       this.sIp=response.data.ip;
       
       });
    
    // axios.get("https://finans.truncgil.com/v2/today.json").then((response=>{
    //   this.lDovizKuru = response.data.USD.Selling
    //   this.update_usd_kuru(this.lDovizKuru);
    // }))

    },
    computed: {
        cartItems() {
            return this.$store.state.eCommerce.cartItems.slice().reverse();
        },
        isInWishList() {
            return (itemId) => this.$store.getters['eCommerce/isInWishList'](itemId)
        },
        ...mapGetters("eCommerce", ["getTradeResult"]),
        ...mapGetters("auth", ["getFirmaResult"])
    },
    methods: {
      paytr(){
        let merchant_id      = "195882";
        let merchant_key     = "aDa7QCyY3NW7rxRM";
        let merchant_salt    = "41bQW8EbGJRqULsb";
        let emailstr         = "recepatabay@gmail.com";
        let payment_amountstr   = 999;
        let merchant_oid     = this.generateUUID().toUpperCase();;
        let user_namestr     = "recep";
        let user_addressstr  = "istanbul";
        let user_phonestr    = "5333329173";
        let merchant_ok_url      = window.location.href;
        let merchant_fail_url    = window.location.href;
        let user_ip = this.sIp;
        
        //var user_basket=[{'Product Name':'Sample Product 1','Unit Price':'18.00','Piece':"1"},{'Product Name':"Sample Product 2", 'Unit Price':"33.25", 'Piece':"2"},{'Product Name':"Sample Product 3", 'Unit Price':"45.42", 'Piece':"1"}];

        var user_basket=[]

        let timeout_limit    = "30";
        let debug_on         = "1";
        let test_mode        = "1";
        let no_installment   = "0";
        let max_installment  = "0";
        let currency         = "TL"; 
        let lang             = "";

        const data = [{merchant_id:merchant_id,user_ip:user_ip,merchant_oid:merchant_oid,email:emailstr,payment_amount:payment_amountstr.toString(),user_basket:'',paytr_token:''}]

        const merchant_params = [{merchant_id:merchant_id,merchant_key:merchant_key,merchant_salt:merchant_salt,debug_on:debug_on,no_installment:no_installment,max_installment:max_installment,timeout_limit:timeout_limit,test_mode:test_mode}]
        const user_params =  [{user_ip:user_ip.toString(),user_name:user_namestr,user_address:user_addressstr,user_phone:user_phonestr,user_basket:user_basket,merchant_oid:merchant_oid,email:emailstr,payment_amount:payment_amountstr,currency:currency,merchant_ok_url:merchant_ok_url,merchant_fail_url:merchant_fail_url,}]
        let body=""
        let callback=""
        //const post_params = [{body:body,callback}]
        const paytr = new PayTR(merchant_params);
        //console.log(paytr);
        //console.log(user_params);
        paytr.getToken(user_params).then((response=>{
          console.log(response)
        }))
        // paytr.getPost(req.body, ({merchant_oid, status}) => {
          
        // });
        

        //JavaScriptSerializer ser = new JavaScriptSerializer();
        //string user_basket_json = ser.Serialize(user_basket);
        //string user_basketstr = Convert.ToBase64String(Encoding.UTF8.GetBytes(user_basket_json));
        let user_basketstr = btoa(this.toUTF8Array(JSON.stringify(user_basket)))
        data["user_basket"] = user_basketstr;

        let Birlestir = [merchant_id, user_ip, merchant_oid, emailstr, payment_amountstr.toString(), user_basketstr, no_installment, max_installment, currency, test_mode, merchant_salt].join('');
        //HMACSHA256 hmac = new HMACSHA256(Encoding.UTF8.GetBytes(merchant_key));
        let hmac = sha256(this.toUTF8Array(merchant_key))
        //let b = hmac.ComputeHash(Encoding.UTF8.GetBytes(Birlestir));
        let b = this.toUTF8Array(Birlestir).toString(CryptoJS.enc.Hex)
        data["paytr_token"] = btoa(b);


        let hash_str = [merchant_id ,user_ip ,merchant_oid, emailstr, payment_amountstr.toString(), user_basketstr,no_installment,max_installment,currency,test_mode].join('');
        let paytr_token=btoa(sha256(hash_str,merchant_salt,merchant_key,true));
        data["paytr_token"] = paytr_token
        //console.log(paytr_token)

        data["debug_on"] = debug_on;
        data["test_mode"] = test_mode;
        data["no_installment"] = no_installment;
        data["max_installment"] = max_installment;
        data["user_name"] = user_namestr;
        data["user_address"] = user_addressstr;
        data["user_phone"] = user_phonestr;
        data["merchant_ok_url"] = merchant_ok_url;
        data["merchant_fail_url"] = merchant_fail_url;
        data["timeout_limit"] = timeout_limit;
        data["currency"] = currency;
        data["lang"] = lang;
        //console.log(data);
        // axios({method:'post',url:'https://www.paytr.com/odeme/api/get-token',data:data,headers: {'Content-Type': 'application/x-www-form-urlencoded','Access-Control-Allow-Headers': 'Origin, X-Requested-With, Access-Control-Allow-Headers, Content-Type, Authorization','Access-Control-Allow-Origin':'http://localhost:8080','Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,OPTIONS','Access-Control-Allow-Credentials':false,'Access-Control-Max-Age':86400}})
        // .then((response=>{
        //   console.log(response)
        // }))

      },
      fromUTF8Array(data) { // array of bytes
    var str = '',
        i;

    for (i = 0; i < data.length; i++) {
        var value = data[i];

        if (value < 0x80) {
            str += String.fromCharCode(value);
        } else if (value > 0xBF && value < 0xE0) {
            str += String.fromCharCode((value & 0x1F) << 6 | data[i + 1] & 0x3F);
            i += 1;
        } else if (value > 0xDF && value < 0xF0) {
            str += String.fromCharCode((value & 0x0F) << 12 | (data[i + 1] & 0x3F) << 6 | data[i + 2] & 0x3F);
            i += 2;
        } else {
            // surrogate pair
            var charCode = ((value & 0x07) << 18 | (data[i + 1] & 0x3F) << 12 | (data[i + 2] & 0x3F) << 6 | data[i + 3] & 0x3F) - 0x010000;

            str += String.fromCharCode(charCode >> 10 | 0xD800, charCode & 0x03FF | 0xDC00); 
            i += 3;
        }
    }

    return str;
},
toUTF8Array(str) {
    var utf8 = [];
    for (var i=0; i < str.length; i++) {
        var charcode = str.charCodeAt(i);
        if (charcode < 0x80) utf8.push(charcode);
        else if (charcode < 0x800) {
            utf8.push(0xc0 | (charcode >> 6), 
                      0x80 | (charcode & 0x3f));
        }
        else if (charcode < 0xd800 || charcode >= 0xe000) {
            utf8.push(0xe0 | (charcode >> 12), 
                      0x80 | ((charcode>>6) & 0x3f), 
                      0x80 | (charcode & 0x3f));
        }
        // surrogate pair
        else {
            i++;
            // UTF-16 encodes 0x10000-0x10FFFF by
            // subtracting 0x10000 and splitting the
            // 20 bits of 0x0-0xFFFFF into two halves
            charcode = 0x10000 + (((charcode & 0x3ff)<<10)
                      | (str.charCodeAt(i) & 0x3ff))
            utf8.push(0xf0 | (charcode >>18), 
                      0x80 | ((charcode>>12) & 0x3f), 
                      0x80 | ((charcode>>6) & 0x3f), 
                      0x80 | (charcode & 0x3f));
        }
    }
    return utf8;
},
    scrollBehavior(id) {
      if (this.getFirmaResult.nFirmaID==0){
      router.push("/login")
      };
      if (id =='odeme'){
        this.kontrol().then(bDevam =>{
                if (bDevam==true){
                  this.$refs.checkoutWizard.nextTab();
                  this.paytr();
                  document.getElementById("checkoutWizard").scrollIntoView();
                }
            })

      }else{
        this.$refs.checkoutWizard.nextTab();
        //document.getElementById("checkoutWizard").scrollIntoView();
        document.getElementById("checkoutWizard").scrollIntoView();
      }
        
        
    },
    printreport(){
    router.push({
            name: "report",
            params: { sFisTipi: 'license',nStokFisiID: this.sevkiyat.nFirmaID }
          });
    },
    setSelectedValue(e) {
      this.bLisanslar = false;
      this.selectedEmployeeNames = "";
      this.selectedRowKeys = [];
      this.tbFirmaLisans.filter("nFirmaID", "=", e.value),
      this.tbFirmaLisans.load().done();
      this.bLisanslar = this.getTradeResult.bLisans;
      const items = this.tbFirma._items;
      const result = items.filter(item => item.nFirmaID === e.value);
      this.sevkiyat.nFirmaID = result[0].nFirmaID;
      this.sevkiyat.sKodu = result[0].sKodu;
      this.sevkiyat.sFirmaAdi = result[0].sAciklama;
      this.sevkiyat.sAdres1 = result[0].sAdres1;
      this.sevkiyat.sAdres2 = result[0].sAdres2;
      this.sevkiyat.sVergiDairesi = result[0].sVergiDairesi;
      this.sevkiyat.sVergiNo = result[0].sVergiNo;
      this.sevkiyat.sSemt = result[0].sSemt;
      this.sevkiyat.sIl = result[0].sIl;
      mobilit.tbFirmaSinifi.byKey(result[0].nFirmaID).then((dataItem) => {
        this.sevkiyat.sSektor = dataItem.sSinifKodu3

      });
      var tbFirmaIletisim = new DataSource({
        store: mobilit.tbFirmaIletisimi,
        filter: [["nFirmaID","=",this.sevkiyat.nFirmaID]],
      });
      var sYetkili ='';
      var sTelefon ='';
      var sMail ='';
      tbFirmaIletisim.load().done(function (itm){
        let arr = itm;
            arr.forEach(function(it){
              if (it.sIletisimAraci=='Yetkili'){
                sYetkili = it.sIletisimAdresi
              }else if (it.sIletisimAraci=="Telefon"){
              sTelefon = it.sIletisimAdresi
              }else if (it.sIletisimAraci=="E-Mail"){
                sMail = it.sIletisimAdresi
              }
          });
      }).then(()=>{
      this.sevkiyat.sTelefon= sTelefon;
      this.sevkiyat.sMail= sMail,
      this.sevkiyat.sYetkili=sYetkili
      });
      
      this.disabled = true;
      //If nFirmaID = 508 Or nFirmaID = 36 Or nFirmaID = 444 Or nFirmaID = 1196 Then
      if (this.getFirmaResult.nFirmaID ==508 || this.getFirmaResult.nFirmaID ==36 || this.getFirmaResult.nFirmaID ==444 || this.getFirmaResult.nFirmaID ==1196){
        this.bDisabled = false;

      }
      else {
        this.bDisabled = true;
        }

      // this.tbFirmaIletisimi.filter("nFirmaID", "=", e.value)
      // this.tbFirmaIletisimi.load().done(function (data) {

      // })
    },
    onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      if (!this.selectionChangedBySelectBox) {
        this.prefix = null;
      }
      this.selectedEmployeeNames = getEmployeeNames(selectedRowsData);
      this.selectedRowKeys = selectedRowKeys;
      let arr = this.$store.state.eCommerce.cartItems.slice();
      var self = this;
      arr.forEach(async (itm,index) =>{
        if (itm.sKodu ==='LisansTemizleme' || itm.sKodu ==='KarpinAylikLisans' || itm.sKodu ==='KarpinYillikLisans' || itm.sKodu === 'LisansDurdur'){
          self.$store.dispatch("eCommerce/updateItemQuantity", {
            quantity: self.selectedRowKeys.length,
            index: index
          });
        }
      });
      
      this.selectionChangedBySelectBox = false;
    },
    updateCardNumber(val) {},
    updateCardName(val) {},
    updateCardMonth(val) {},
    updateCardYear(val) {},
    updateCardCvv(val) {},

    // TAB 1
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
    },
    wishListButtonClicked(item) {
      // Toggle in Wish List
      if (this.isInWishList(item.nStokID))
        router.push("/apps/eCommerce/wish-list");
      else {
        this.toggleItemInWishList(item);
        this.removeItemFromCart(item);
      }
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    removeItems(){
      this.$store.dispatch("eCommerce/removeItems");
      this.$router.push('/shop')
    },
    update_usd_kuru(lDovizKuru){
      this.$store.dispatch("eCommerce/updateUSDKuru",lDovizKuru)
    },
    updateItemQuantity(event, index) {
      const itemIndex = Math.abs(index + 1 - this.cartItems.length);
      this.$store.dispatch("eCommerce/updateItemQuantity", {
        quantity: event,
        index: itemIndex
      });
    },

    // TAB 2
    submitNewAddressForm() {
      return new Promise(() => {
        this.$validator.validateAll("add-new-address").then(result => {
          if (result) {
            // if form have no errors
            self.kontrol().then(bDevam =>{
                if (bDevam==true){
                  this.$refs.checkoutWizard.nextTab();
                }
            })
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Please enter valid details",
              color: "warning",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          }
        });
      });
    }, 
    addDays(date,days){
      var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    generateUUID(){
    var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },
    firma_ekle(sKodu){
       var self = this;
      return new Promise((resolve, reject) => {
      this.tbfirma.sKodu = sKodu.toString();//this.generateUUID().toUpperCase();
      this.tbfirma.sAciklama = this.sevkiyat.sFirmaAdi;
      this.tbfirma.sAdres1 = this.sevkiyat.sAdres1;
      this.tbfirma.sAdres2 = this.sevkiyat.sAdres2;
      this.tbfirma.sSemt = this.sevkiyat.sSemt;
      this.tbfirma.sIl = this.sevkiyat.sIl;
      this.tbfirma.sVergiDairesi = this.sevkiyat.sVergiDairesi;
      this.tbfirma.sVergiNo = this.sevkiyat.sVergiNo;
      this.tbfirma.sSaticiRumuzu = this.tbstokfisimaster.nFirmaID.toString();
      //tbhesapplani
      this.tbhesapplani.sKodu = this.tbfirma.sKodu;
      this.tbhesapplani.sAciklama = this.tbfirma.sAciklama;
      
    mobilit.tbHesapPlani.insert(this.tbhesapplani).done((values,newId)=>{
      console.log('hesap eklendi')
        this.tbhesapsinifi.nHesapID = newId,
        mobilit.tbHesapSinifi.insert(this.tbhesapsinifi).done(console.log('hesap sinifi eklendi'));
        this.tbfirma.nHesapID = newId;
        mobilit.tbFirma.insert(this.tbfirma).done((values,newId)=>{
          console.log('firma eklendi...'+ newId);
          //this.sevkiyat.nFirmaID = newId;
          this.tbfirmasinifi.nFirmaID = newId;
          this.tbfirmasinifi.sSinifKodu3 = this.sevkiyat.sSektor;
          mobilit.tbFirmaSinifi.insert(this.tbfirmasinifi).done(console.log('firma sinifi eklendi'));
          this.tbfirmaaciklamasi.nFirmaID = newId;
          mobilit.tbFirmaAciklamasi.insert(this.tbfirmaaciklamasi).done(console.log('firma aciklamasi eklendi'));
          if (this.sevkiyat.sTelefon !==""){
            this.tbfirmailetisimi.nFirmaID = newId;
            this.tbfirmailetisimi.sIletisimAraci ="Telefon";
            this.tbfirmailetisimi.sIletisimAdresi = this.sevkiyat.sTelefon;
            mobilit.tbFirmaIletisimi.insert(this.tbfirmailetisimi).done(console.log('telefon eklendi'));
          };
          if (this.sevkiyat.sMail !==""){
            this.tbfirmailetisimi.nFirmaID = newId;
            this.tbfirmailetisimi.sIletisimAraci ="E-Mail";
            this.tbfirmailetisimi.sIletisimAdresi = this.sevkiyat.sMail;
            mobilit.tbFirmaIletisimi.insert(this.tbfirmailetisimi).done(console.log('mail eklendi'));
          };
          if (this.sevkiyat.sYetkili !==""){
            this.tbfirmailetisimi.nFirmaID = newId;
            this.tbfirmailetisimi.sIletisimAraci ="Yetkili";
            this.tbfirmailetisimi.sIletisimAdresi = this.sevkiyat.sYetkili;
            mobilit.tbFirmaIletisimi.insert(this.tbfirmailetisimi).done(console.log('yetkili eklendi'));
          };
            this.sFirma = "<p>"+this.$t('sevkiyatadresi')+";</p>"
            this.sFirma += "<p>"
            this.sFirma += "<strong>"+this.$t('kayitno')+":</strong> " + newId + "<br>"
            this.sFirma += "<strong>"+this.$t('aciklama')+":</strong> " + this.sevkiyat.sFirmaAdi + "<br>"
            this.sFirma += "<strong>"+this.$t('adres')+":</strong> " + this.sevkiyat.sAdres1 + "<br>"
            this.sFirma += "<strong>"+this.$t('adres')+":</strong> " + this.sevkiyat.sAdres2 + "<br>"
            this.sFirma += "<strong>"+this.$t('il')+":</strong> " + this.sevkiyat.sIl + "<br>"
            this.sFirma += "<strong>"+this.$t('sehir')+":</strong> " + this.sevkiyat.sSemt+ "<br>"
            this.sFirma += "<strong>"+this.$t('vergidairesi')+":</strong> " + this.sevkiyat.sVergiDairesi + "<br>"
            this.sFirma += "<strong>"+this.$t('vkn')+":</strong> " + this.sevkiyat.sVergiNo + "<br>"
            this.sFirma += "<strong>"+this.$t('email')+":</strong> " + this.sevkiyat.sMail + "<br>"
            this.sFirma += "<strong>"+this.$t('telefon')+":</strong> " + this.sevkiyat.sTelefon + "<br>"
            this.sFirma += "<strong>"+this.$t('yetkili')+":</strong> " + this.sevkiyat.sYetkili + "<br>"
            this.sFirma += "<strong>"+this.$t('sektor')+":</strong> " + this.sevkiyat.sSektor + "<br>"
            this.sFirma += "<strong>"+this.$t('notlar')+":</strong> " + this.sevkiyat.sNot + "<br>"
            this.sFirma += "</p>"
            //this.sevkiyat.nFirmaID = newId;
            //console.log(this.sevkiyat);
            //self.sevkiyat.nFirmaID = newId;
          resolve(newId);
        });

    })
     
    })
    },
    modul_ekle(nFirmaID,sModul,sPaket,sAciklama){
      var result =''
      this.Modul.nFirmaID = nFirmaID;
      this.Modul.sModul = sModul;
      this.Modul.sPaket = sPaket;
      this.Modul.sAciklama = sAciklama;
      return new Promise((resolve, reject) => {
        mobilit.tbFirmaModulu.insert(this.Modul).done((values,newId)=>{
        if (this.sModuller==''){
          this.sModuller +=   "<br>"  +"Modüller;"  + "<br>" + values.sModul
        }else{
          this.sModuller +="+" + values.sModul
        }
       
        console.log('Eklendi'+newId + ' ' + values.sModul);
        resolve(true);
        });
      })
    },
    kullanici_ekle(nFirmaID,quantity){
      return new Promise((resolve, reject) => {
      this.sOnayKodlari = "<ul>"+this.$t('lisanslar')+";"
      var sayi = 0;
      for (var i = 0; i < quantity; i++) {
        this.Lisans.sOnayKodu = this.generateUUID().toUpperCase();
             this.Lisans.nFirmaID = nFirmaID;
             this.Lisans.nBayiID = this.getFirmaResult.nFirmaID;
             this.Lisans.sParametre1= '';
             this.Lisans.sParametre2= '';
             this.Lisans.sManufactor= '';
             this.Lisans.sModel= '';
             this.Lisans.sSystemType= '';
             this.Lisans.sCpuID= '';
             this.Lisans.sBiosVersion= '';
             this.Lisans.sHddSerial= '';
             this.Lisans.sMacID= '';
             this.Lisans.sBilgisayar= '';
             this.Lisans.sOturum= '';
             this.Lisans.sOS= '';
             this.Lisans.sVer= '';
             this.Lisans.sIP= '';
             this.Lisans.sUlke= '';
             this.Lisans.sBolge= '';
             this.Lisans.sSifreyiAlan= '';
             if (this.getTradeResult.bLisansYillik){
                this.Lisans.dteGecerlilikTarihi= this.addDays(new Date(),365);
             }
             else{
               this.Lisans.dteGecerlilikTarihi= this.addDays(new Date(),45);
             }
             
             this.Lisans.dteKayitTarihi= new Date();
             this.Lisans.dteSonUpdateTarihi= new Date();
         //return new Promise((resolv, reject) => {    
          
          mobilit.tbFirmaLisans.insert(this.Lisans).done((values,newId)=>{
            this.sOnayKodlari += "<li><strong>" + values.sOnayKodu + "</strong></li>"
            console.log('kullanici eklendi'+ newId);
            this.selectedRowKeys.push(newId);
            sayi +=1;
            console.log(sayi);
            console.log(quantity);
            //resolv(true);
            if(sayi === quantity){
              //console.log('esit');
              this.sOnayKodlari += "</ul>"
              resolve(true)
            };
          })

          //})
        }
      })
    },
    kullanici_degistir (nIslem,index){
      return new Promise((resolve, reject) => {
        // this.$store.dispatch("eCommerce/updateItemQuantity", {
        // quantity: this.selectedRowKeys.length,
        // index: index
        // });
      console.log(this.selectedRowKeys);
      var self = this;
      var mobili = mobilit;
      var sayi = 0;
      
      self.selectedRowKeys.forEach(function(item,index){
        mobili.tbFirmaLisans.byKey(item).then((dataItem) => {
          //debugger;
          //self.sOnayKodlari += dataItem.sOnayKodu + "|" + dataItem.sParametre1 + "|" + dataItem.sParametre2 + "| Üretici:" + dataItem.sManufactor + "|Model:" + dataItem.sModel + "|Sistem:" + dataItem.sSystemType + "|Cpu:" + dataItem.sCpuID  + "|Bios:" + dataItem.sBiosVersion  + "|HddSerial:" + dataItem.sHddSerial + "|MacID:" + dataItem.sMacID + "|Bilgisayar:" + dataItem.sBilgisayar + "|Oturum:" + dataItem.sOturum + "|İşletim Sistemi:" + dataItem.sOS + "|Version:" + dataItem.sVer + "|Ip:" + dataItem.sIP + "|Şifre Alan:" + dataItem.sSifreyiAlan + "|Geçerlilik Tarihi:" + dataItem.dteGecerlilikTarihi +  '\r\n'
            if (nIslem ==0){
                
                
                self.sOnayKodlari += "<ul>Aşağıdaki Lisansın Süresi " + self.addDays(new Date(),0).toLocaleString() + " Tarihi itibariyle Durdurulmuştur";
                self.sOnayKodlari += "<li><strong>" + dataItem.sOnayKodu + "</strong></li>";
                self.sOnayKodlari += "</ul>";
                self.sOnayKodlari += "" + dataItem.sOnayKodu + "|" + dataItem.sParametre1 + "|" + dataItem.sParametre2 + "| Üretici:" + dataItem.sManufactor + "|Model:" + dataItem.sModel + "|Sistem:" + dataItem.sSystemType + "|Cpu:" + dataItem.sCpuID  + "|Bios:" + dataItem.sBiosVersion  + "|HddSerial:" + dataItem.sHddSerial + "|MacID:" + dataItem.sMacID + "|Bilgisayar:" + dataItem.sBilgisayar + "|Oturum:" + dataItem.sOturum + "|İşletim Sistemi:" + dataItem.sOS + "|Version:" + dataItem.sVer + "|Ip:" + dataItem.sIP + "|Şifre Alan:" + dataItem.sSifreyiAlan + "|Geçerlilik Tarihi:" + dataItem.dteGecerlilikTarihi.toLocaleString()  + "<br>"

                dataItem.dteGecerlilikTarihi = self.addDays(new Date(),0);
                dataItem.dteSonUpdateTarihi = new Date();
            }
            else if (nIslem ==1){
              
                
                self.sOnayKodlari += "<ul>Aşağıdaki Lisansın Süresi " + self.addDays(new Date(),30).toLocaleString() + " Tarihine Kadar Uzatılmıştır";
                self.sOnayKodlari += "<li><strong>" + dataItem.sOnayKodu + "</strong></li>";
                self.sOnayKodlari += "</ul>";
                self.sOnayKodlari += "" + dataItem.sOnayKodu + "|" + dataItem.sParametre1 + "|" + dataItem.sParametre2 + "| Üretici:" + dataItem.sManufactor + "|Model:" + dataItem.sModel + "|Sistem:" + dataItem.sSystemType + "|Cpu:" + dataItem.sCpuID  + "|Bios:" + dataItem.sBiosVersion  + "|HddSerial:" + dataItem.sHddSerial + "|MacID:" + dataItem.sMacID + "|Bilgisayar:" + dataItem.sBilgisayar + "|Oturum:" + dataItem.sOturum + "|İşletim Sistemi:" + dataItem.sOS + "|Version:" + dataItem.sVer + "|Ip:" + dataItem.sIP + "|Şifre Alan:" + dataItem.sSifreyiAlan + "|Geçerlilik Tarihi:" + dataItem.dteGecerlilikTarihi.toLocaleString() + "<br>"

                dataItem.dteGecerlilikTarihi = self.addDays(new Date(),30);
                dataItem.dteSonUpdateTarihi = new Date();
            }
            else if (nIslem ==2){
                
                
                self.sOnayKodlari += "<ul>Aşağıdaki Lisansın Süresi " + self.addDays(new Date(),365).toLocaleString() + " Tarihine Kadar Uzatılmıştır";
                self.sOnayKodlari += "<li><strong>" + dataItem.sOnayKodu + "</strong></li>";
                self.sOnayKodlari += "</ul>";
                self.sOnayKodlari += "" + dataItem.sOnayKodu + "|" + dataItem.sParametre1 + "|" + dataItem.sParametre2 + "| Üretici:" + dataItem.sManufactor + "|Model:" + dataItem.sModel  + "|Sistem:" + dataItem.sSystemType + "|Cpu:" + dataItem.sCpuID  + "|Bios:" + dataItem.sBiosVersion  + "|HddSerial:" + dataItem.sHddSerial + "|MacID:" + dataItem.sMacID + "|Bilgisayar:" + dataItem.sBilgisayar + "|Oturum:" + dataItem.sOturum + "|İşletim Sistemi:"  + dataItem.sOS + "|Version:" + dataItem.sVer + "|Ip:" + dataItem.sIP + "|Şifre Alan:" + dataItem.sSifreyiAlan + "|Geçerlilik Tarihi:" + dataItem.dteGecerlilikTarihi.toLocaleString() + "<br>"
                dataItem.dteGecerlilikTarihi = self.addDays(new Date(),365);
                dataItem.dteSonUpdateTarihi = new Date();
                }
            else if (nIslem ==3){
                
                
                self.sOnayKodlari += "<ul>Aşağıdaki Lisansın Bilgisayar Bilgisi " + dataItem.dteGecerlilikTarihi.toLocaleString() + " Tarihi itibariyle Kaldırılmıştır";
                self.sOnayKodlari += "<li><strong>" + dataItem.sOnayKodu + "</strong></li>";
                self.sOnayKodlari += "</ul>";
                self.sOnayKodlari += "" + dataItem.sOnayKodu + "|" + dataItem.sParametre1 + "|" + dataItem.sParametre2 + "| Üretici:" + dataItem.sManufactor + "|Model:" + dataItem.sModel  + "|Sistem:" + dataItem.sSystemType + "|Cpu:" + dataItem.sCpuID  + "|Bios:" + dataItem.sBiosVersion  + "|HddSerial:" + dataItem.sHddSerial + "|MacID:" + dataItem.sMacID + "|Bilgisayar:" + dataItem.sBilgisayar + "|Oturum:" + dataItem.sOturum + "|İşletim Sistemi:"  + dataItem.sOS + "|Version:" + dataItem.sVer + "|Ip:" + dataItem.sIP + "|Şifre Alan:" + dataItem.sSifreyiAlan + "|Geçerlilik Tarihi:" + dataItem.dteGecerlilikTarihi.toLocaleString() + "<br>"

                dataItem.sParametre1 ='';
                dataItem.sParametre2 ='';
                dataItem.sManufactor ='';
                dataItem.sModel ='';
                dataItem.sSystemType ='';   
                dataItem.sCpuID ='';
                dataItem.sBiosVersion ='';
                dataItem.sHddSerial ='';
                dataItem.sMacID ='';
                dataItem.sBilgisayar='';
                dataItem.sOturum ='';
                dataItem.sOS='';
                dataItem.sVer='';
                dataItem.sUlke='';
                dataItem.sBolge='';
                dataItem.sSifreyiAlan='';
                dataItem.dteSonUpdateTarihi = new Date();
            }
             self.Lisans.sOnayKodu = dataItem.sOnayKodu;
             self.Lisans.nFirmaID = dataItem.nFirmaID;
             self.Lisans.nBayiID = dataItem.nBayiID;
             self.Lisans.sParametre1= dataItem.sParametre1;
             self.Lisans.sParametre2= dataItem.sParametre2;
             self.Lisans.sManufactor= dataItem.sManufactor;
             self.Lisans.sModel= dataItem.sModel;
             self.Lisans.sSystemType= dataItem.sSystemType;
             self.Lisans.sCpuID= dataItem.sCpuID;
             self.Lisans.sBiosVersion= dataItem.sBiosVersion;
             self.Lisans.sHddSerial= dataItem.sHddSerial;
             self.Lisans.sMacID= dataItem.sMacID;
             self.Lisans.sBilgisayar= dataItem.sBilgisayar;
             self.Lisans.sOturum= dataItem.sOturum;
             self.Lisans.sOS= dataItem.sOS;
             self.Lisans.sVer= dataItem.sVer;
             self.Lisans.sIP= dataItem.sIP;
             self.Lisans.sUlke= dataItem.sUlke;
             self.Lisans.sBolge= dataItem.sBolge;
             self.Lisans.sSifreyiAlan= dataItem.sSifreyiAlan;
             self.Lisans.dteGecerlilikTarihi= dataItem.dteGecerlilikTarihi;
             self.Lisans.dteKayitTarihi= dataItem.dteKayitTarihi;
             self.Lisans.dteSonUpdateTarihi= dataItem.dteSonUpdateTarihi;
             return new Promise((resolv, reject) => {
               mobili.tbFirmaLisans.update(self.Lisans.sOnayKodu,self.Lisans).done(()=>{
               console.log('degisti' + item);
               sayi +=1;
               if (sayi === self.selectedRowKeys.length){
                 //console.log(self.sOnayKodlari);
                 resolve(true);
               };
               resolv(true);
               })
              });
          });
          }) ;
      })
    
    },
    satir_islem(itm,index){
      return new Promise((resolve, reject) => {
        var self = this;
     if(itm.sKodu =='PosScreen'){
       self.sorgu_sKodu().then((sKodu)=>{ console.log('yeni Kod'+sKodu); 
        self.firma_ekle(sKodu).then((nFirmaID)=>{
            
              self.modul_ekle(nFirmaID,'Peşin',itm.sKodu,'').then(()=>{
                self.modul_ekle(nFirmaID,'Cari',itm.sKodu,'').then(()=>{
                  self.modul_ekle(nFirmaID,'Stok',itm.sKodu,'').then(()=>{
                    self.modul_ekle(nFirmaID,'Fatura',itm.sKodu,'').then(()=>{
                      self.modul_ekle(nFirmaID,'Etiket',itm.sKodu,'').then(()=>{
                        self.modul_ekle(nFirmaID,'Ajanda',itm.sKodu,'').then(()=>{
                          self.modul_ekle(nFirmaID,'Fihrist',itm.sKodu,'').then(()=>{
                            self.kullanici_ekle(nFirmaID,1).then(result =>{resolve(result)});
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
       }
     else if(itm.sKodu =='Turab'){
          self.modul_ekle(self.getTradeResult.nFirmaID,'Turab',itm.sKodu,'').then(()=>{
            resolve(true);
          });
                
     }
     else if(itm.sKodu =='KapinEntegre'){
          self.modul_ekle(self.getTradeResult.nFirmaID,'B2C',itm.sKodu,'').then(()=>{
            resolve(true);
          });
                
     }
     else if(itm.sKodu =='KapinMobile'){
          self.modul_ekle(self.getTradeResult.nFirmaID,'Karpin Mobile',itm.sKodu,'').then(()=>{
            resolve(true);
          });
                
     }
     else if(itm.sKodu =='KarpinPos'){
       self.sorgu_sKodu().then((sKodu)=>{ console.log('yeni Kod'+sKodu); 
        self.firma_ekle(sKodu).then((nFirmaID)=>{
            
              self.modul_ekle(nFirmaID,'Peşin',itm.sKodu,'').then(()=>{
                self.modul_ekle(nFirmaID,'Cari',itm.sKodu,'').then(()=>{
                  self.modul_ekle(nFirmaID,'Stok',itm.sKodu,'').then(()=>{
                    self.modul_ekle(nFirmaID,'Fatura',itm.sKodu,'').then(()=>{
                      self.modul_ekle(nFirmaID,'Etiket',itm.sKodu,'').then(()=>{
                        self.modul_ekle(nFirmaID,'Ajanda',itm.sKodu,'').then(()=>{
                          self.modul_ekle(nFirmaID,'Fihrist',itm.sKodu,'').then(()=>{
                            self.kullanici_ekle(nFirmaID,1).then(result =>{resolve(result)});
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
       }
       else if(itm.sKodu =='KarpinKobi'){
       self.sorgu_sKodu().then((sKodu)=>{ console.log('yeni Kod'+sKodu); 
        self.firma_ekle(sKodu).then((nFirmaID)=>{
            self.modul_ekle(nFirmaID,'Cari',itm.sKodu,'').then(()=>{
                self.modul_ekle(nFirmaID,'Stok',itm.sKodu,'').then(()=>{
                  self.modul_ekle(nFirmaID,'Fatura',itm.sKodu,'').then(()=>{
                    self.modul_ekle(nFirmaID,'İrsaliye',itm.sKodu,'').then(()=>{
                      self.modul_ekle(nFirmaID,'Çek/Senet',itm.sKodu,'').then(()=>{
                        self.modul_ekle(nFirmaID,'Kasa',itm.sKodu,'').then(()=>{
                          self.modul_ekle(nFirmaID,'Banka',itm.sKodu,'').then(()=>{
                            self.modul_ekle(nFirmaID,'Ajanda',itm.sKodu,'').then(()=>{
                              self.modul_ekle(nFirmaID,'Fihrist',itm.sKodu,'').then(()=>{
                            self.kullanici_ekle(nFirmaID,1).then(result =>{resolve(result)});
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
       }
      else if(itm.sKodu =='KarpinTicari'){
       self.sorgu_sKodu().then((sKodu)=>{ console.log('yeni Kod'+sKodu); 
        self.firma_ekle(sKodu).then((nFirmaID)=>{
            self.modul_ekle(nFirmaID,'Cari',itm.sKodu,'').then(()=>{
                self.modul_ekle(nFirmaID,'Stok',itm.sKodu,'').then(()=>{
                  self.modul_ekle(nFirmaID,'Fatura',itm.sKodu,'').then(()=>{
                    self.modul_ekle(nFirmaID,'İrsaliye',itm.sKodu,'').then(()=>{
                      self.modul_ekle(nFirmaID,'Sipariş',itm.sKodu,'').then(()=>{
                        self.modul_ekle(nFirmaID,'Çek/Senet',itm.sKodu,'').then(()=>{
                          self.modul_ekle(nFirmaID,'Kasa',itm.sKodu,'').then(()=>{
                            self.modul_ekle(nFirmaID,'Banka',itm.sKodu,'').then(()=>{
                              self.modul_ekle(nFirmaID,'Muhasebe',itm.sKodu,'').then(()=>{
                                self.modul_ekle(nFirmaID,'Sayım',itm.sKodu,'').then(()=>{
                                  self.modul_ekle(nFirmaID,'Etiket',itm.sKodu,'').then(()=>{
                                    self.modul_ekle(nFirmaID,'Ajanda',itm.sKodu,'').then(()=>{
                                      self.modul_ekle(nFirmaID,'Fihrist',itm.sKodu,'').then(()=>{
                                        self.kullanici_ekle(nFirmaID,1).then(result =>{resolve(result)});
                                   });
                                  });
                                 });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
       }
     else if(itm.sKodu =='KarpinPerakende'){
       self.sorgu_sKodu().then((sKodu)=>{ console.log('yeni Kod'+sKodu); 
        self.firma_ekle(sKodu).then((nFirmaID)=>{
            self.modul_ekle(nFirmaID,'Perakende',itm.sKodu,'').then(result =>{
              self.modul_ekle(nFirmaID,'Peşin',itm.sKodu,'').then(result =>{
                self.modul_ekle(nFirmaID,'Cari',itm.sKodu,'').then(result =>{
                  self.modul_ekle(nFirmaID,'Stok',itm.sKodu,'').then(result =>{
                    self.modul_ekle(nFirmaID,'Fatura',itm.sKodu,'').then(result =>{
                      self.modul_ekle(nFirmaID,'İrsaliye',itm.sKodu,'').then(result =>{
                        self.modul_ekle(nFirmaID,'Sipariş',itm.sKodu,'').then(result =>{
                          self.modul_ekle(nFirmaID,'Çek/Senet',itm.sKodu,'').then(result =>{
                            self.modul_ekle(nFirmaID,'Kasa',itm.sKodu,'').then(result =>{
                              self.modul_ekle(nFirmaID,'Banka',itm.sKodu,'').then(result =>{
                                self.modul_ekle(nFirmaID,'Sayım',itm.sKodu,'').then(result =>{
                                  self.modul_ekle(nFirmaID,'Market',itm.sKodu,'Online').then(result =>{
                                    self.modul_ekle(nFirmaID,'Etiket',itm.sKodu,'').then(result =>{
                                      self.modul_ekle(nFirmaID,'Ajanda',itm.sKodu,'').then(result =>{
                                        self.modul_ekle(nFirmaID,'Fihrist',itm.sKodu,'').then(result =>{
                                          self.kullanici_ekle(nFirmaID,1).then(result =>{resolve(result)});
                                         });
                                        });
                                      });
                                    });
                                  });
                                });
                               });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            })

        }
        else if(itm.sKodu =='KarpinMarket'){
       self.sorgu_sKodu().then((sKodu)=>{ console.log('yeni Kod'+sKodu); 
        self.firma_ekle(sKodu).then((nFirmaID)=>{
            self.modul_ekle(nFirmaID,'Perakende',itm.sKodu,'').then(() =>{
              self.modul_ekle(nFirmaID,'Peşin',itm.sKodu,'').then(()=>{
                self.modul_ekle(nFirmaID,'Cari',itm.sKodu,'').then(()=>{
                  self.modul_ekle(nFirmaID,'Stok',itm.sKodu,'').then(()=>{
                    self.modul_ekle(nFirmaID,'Fatura',itm.sKodu,'').then(()=>{
                      self.modul_ekle(nFirmaID,'İrsaliye',itm.sKodu,'').then(()=>{
                        self.modul_ekle(nFirmaID,'Sipariş',itm.sKodu,'').then(()=>{
                          self.modul_ekle(nFirmaID,'Çek/Senet',itm.sKodu,'').then(()=>{
                            self.modul_ekle(nFirmaID,'Kasa',itm.sKodu,'').then(()=>{
                              self.modul_ekle(nFirmaID,'Banka',itm.sKodu,'').then(()=>{
                                self.modul_ekle(nFirmaID,'Sayım',itm.sKodu,'').then(()=>{
                                  self.modul_ekle(nFirmaID,'Market',itm.sKodu,'Online').then(()=>{
                                    self.modul_ekle(nFirmaID,'Turab',itm.sKodu,'Online').then(()=>{
                                    self.modul_ekle(nFirmaID,'Etiket',itm.sKodu,'').then(()=>{
                                      self.modul_ekle(nFirmaID,'Ajanda',itm.sKodu,'').then(()=>{
                                        self.modul_ekle(nFirmaID,'Fihrist',itm.sKodu,'').then(()=>{
                                          self.kullanici_ekle(nFirmaID,1).then(result =>{resolve(result)});
                                         });
                                        });
                                      });
                                    });
                                  });
                                });
                               });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
            })

        }
        else if(itm.sKodu =='KarpinERP'){
       self.sorgu_sKodu().then((sKodu)=>{ console.log('yeni Kod'+sKodu); 
        self.firma_ekle(sKodu).then((nFirmaID)=>{
            self.modul_ekle(nFirmaID,'Perakende',itm.sKodu,'').then(() =>{
              self.modul_ekle(nFirmaID,'Sonradan Teslim',itm.sKodu,'').then(()=>{
              self.modul_ekle(nFirmaID,'Peşin',itm.sKodu,'').then(()=>{
                self.modul_ekle(nFirmaID,'Cari',itm.sKodu,'').then(()=>{
                  self.modul_ekle(nFirmaID,'Stok',itm.sKodu,'').then(()=>{
                    self.modul_ekle(nFirmaID,'Fatura',itm.sKodu,'').then(()=>{
                      self.modul_ekle(nFirmaID,'İrsaliye',itm.sKodu,'').then(()=>{
                        self.modul_ekle(nFirmaID,'Sipariş',itm.sKodu,'').then(()=>{
                          self.modul_ekle(nFirmaID,'Çek/Senet',itm.sKodu,'').then(()=>{
                            self.modul_ekle(nFirmaID,'Kasa',itm.sKodu,'').then(()=>{
                              self.modul_ekle(nFirmaID,'Banka',itm.sKodu,'').then(()=>{
                                self.modul_ekle(nFirmaID,'Muhasebe',itm.sKodu,'').then(()=>{
                                self.modul_ekle(nFirmaID,'Sayım',itm.sKodu,'').then(()=>{
                                  self.modul_ekle(nFirmaID,'Market',itm.sKodu,'Online').then(()=>{
                                    self.modul_ekle(nFirmaID,'Paket',itm.sKodu,'Online').then(()=>{
                                    self.modul_ekle(nFirmaID,'Turab',itm.sKodu,'Online').then(()=>{
                                      self.modul_ekle(nFirmaID,'Protokol',itm.sKodu,'Online').then(()=>{
                                    self.modul_ekle(nFirmaID,'Etiket',itm.sKodu,'').then(()=>{
                                      self.modul_ekle(nFirmaID,'Ajanda',itm.sKodu,'').then(()=>{
                                        self.modul_ekle(nFirmaID,'Fihrist',itm.sKodu,'').then(()=>{
                                          self.kullanici_ekle(nFirmaID,1).then(result =>{resolve(result)});
                                         });
                                        });
                                      });
                                    });
                                  });
                                });
                               });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
            });
            });
            });
            });
            })

        }
        else if(itm.sKodu =='KarpinSaha'){
       self.sorgu_sKodu().then((sKodu)=>{ console.log('yeni Kod'+sKodu); 
        self.firma_ekle(sKodu).then((nFirmaID)=>{
            self.modul_ekle(nFirmaID,'Cari',itm.sKodu,'').then(()=>{
                self.modul_ekle(nFirmaID,'Stok',itm.sKodu,'').then(()=>{
                  self.modul_ekle(nFirmaID,'Fatura',itm.sKodu,'').then(()=>{
                    self.modul_ekle(nFirmaID,'İrsaliye',itm.sKodu,'').then(()=>{
                      self.modul_ekle(nFirmaID,'Çek/Senet',itm.sKodu,'').then(()=>{
                        self.modul_ekle(nFirmaID,'Kasa',itm.sKodu,'').then(()=>{
                          self.modul_ekle(nFirmaID,'Banka',itm.sKodu,'').then(()=>{
                            self.modul_ekle(nFirmaID,'Karpin',itm.sKodu,'').then(()=>{
                            self.modul_ekle(nFirmaID,'Ajanda',itm.sKodu,'').then(()=>{
                              self.modul_ekle(nFirmaID,'Fihrist',itm.sKodu,'').then(()=>{
                            self.kullanici_ekle(nFirmaID,1).then(result =>{resolve(result)});
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
        });
       }
        else if(itm.sKodu =='KarpinKullanici'){
             self.kullanici_ekle(self.sevkiyat.nFirmaID,parseInt(itm.quantity)).then(result => {resolve(result)});
        }
        else if(itm.sKodu =='LisansDurdur'){
             self.kullanici_degistir(0,index).then(result => {resolve(result)});
        }
        else if(itm.sKodu =='KarpinAylikLisans'){
             self.kullanici_degistir(1,index).then(result => {resolve(result)});
        }
        else if(itm.sKodu =='KarpinYillikLisans'){
            self.kullanici_degistir(2,index).then(result => {resolve(result)});
        }
        else if(itm.sKodu =='LisansTemizleme'){
            self.kullanici_degistir(3,index).then(result => {resolve(result)});
        }
        else {resolve(false)}



      })

    },
    sorgu_sKodu(){
    return new Promise((resolve,reject) => {
      mobilit.tbFirma.load({
        select:["sKodu"],
        filter: ([["sKodu", "startswith", "10"]]),
        sort: { field: "sKodu", desc: true },
        pageSize: 10,
      }).done((data)=>{
        Query(data)
            .select('sKodu')
            .max('sKodu')
            .then(result => {
              if (Number.isNaN(result)){
                result =0
              };
                  resolve(Number(result)+1)
              });

      })

    })
    },
    sorgu_lFisNo(){
    return new Promise((resolve,reject) => {
         resolve(Math.floor(Math.random() * 10)); // 0 ve 9 arasında sayı üretir.

    //   mobilit.tbStokFisiMaster.load({
    //     select:["lFisNo"],
    //     filter: ([["sFisTipi", "=", "FS"], "and", ["dteFisTarihi", ">=", this.addDays(new Date(),-30)], "and", ["dteFisTarihi", "<=", this.addDays(new Date(),0)]]),
    //   }).done((data)=>{
    //     Query(data)
    //         .select('lFisNo')
    //         .max('lFisNo')
    //         .then(result => {
    //           if (Number.isNaN(result)){
    //             result =0
    //           };
    //               resolve(result+1)
    //           });

    //   })

    })
    },
    sorgu_nStokID(sKodu,sAciklama){
      return new Promise((resolve,reject) => {
           resolve(Math.floor(Math.random() * 10)); // 0 ve 9 arasında sayı üretir.
    //     if (sKodu.length>20){
    //       sKodu = sKodu.toString().substring(0, 20);
    //     };
    //     if (sAciklama.length>60){
    //       sAciklama = sAciklama.toString().substring(0, 60)
    //     };
    //   mobilit.tbStok.load({
    //     select:["nStokID"],
    //     filter: (["sKodu", "=", sKodu]),
    //   }).done((data)=>{
    //     Query(data)
    //         .select('nStokID')
    //         .max('nStokID')
    //         .then(result => {
    //           if (Number.isNaN(result)){
    //             this.tbstok.sKodu= sKodu;
    //             this.tbstok.sAciklama= sAciklama;
    //             mobilit.tbStok.insert(this.tbstok).done((values,newId)=>{
    //               this.tbstoksinifi.nStokID= newId;
    //               mobilit.tbStokSinifi.insert(this.tbstoksinifi).done();
    //               this.tbstokaciklama.nStokID = newId;
    //               mobilit.tbStokAciklama.insert(this.tbstokaciklama).done();
    //               resolve(newId);
    //             })
    //           }else {resolve(result)}
    //           });

    //   })

    })
    },
    tbstokfisidetayi_ekle(nStokFisiID,nStokID,sAciklama,lCikisMiktar1,nKdvOrani,lBrutFiyat,lBrutTutar,sDovizCinsi,lDovizFiyat){
      return new Promise((resolve,reject) => {
        this.tbstokfisidetayi.nStokID = nStokID;
        this.tbstokfisidetayi.nStokFisiID = nStokFisiID;
        this.tbstokfisidetayi.nFirmaID = this.tbstokfisimaster.nFirmaID;
        this.tbstokfisidetayi.dteFisTarihi = this.tbstokfisimaster.dteFisTarihi;
        this.tbstokfisidetayi.dteIslemTarihi = this.tbstokfisimaster.dteFisTarihi;
        this.tbstokfisidetayi.sFisTipi = this.tbstokfisimaster.sFisTipi;
        this.tbstokfisidetayi.lFisNo = this.tbstokfisimaster.lFisNo;
        this.tbstokfisidetayi.sStokIslem = "";
        this.tbstokfisidetayi.sHangiUygulama = this.tbstokfisimaster.sFisTipi;
        this.tbstokfisidetayi.lCikisMiktar1 = lCikisMiktar1;
        this.tbstokfisidetayi.lBrutFiyat = lBrutFiyat;
        this.tbstokfisidetayi.lBrutTutar = lBrutTutar;
        this.tbstokfisidetayi.nKdvOrani = nKdvOrani;
        this.tbstokfisidetayi.lCikisFiyat = lBrutFiyat;
        this.tbstokfisidetayi.lCikisTutar = lBrutTutar;
        this.tbstokfisidetayi.sDovizCinsi = sDovizCinsi;
        this.tbstokfisidetayi.lDovizFiyat = lDovizFiyat;
        this.tbstokfisidetayi.sAciklama = "Isk1:00.00 Isk2:00.00 Isk3:00.00 Isk4:00.00";
        this.tbstokfisidetayi.sKullaniciAdi = sAciklama;
        //console.log(this.tbstokfisidetayi);
        //mobilit.tbStokFisiDetayi.insert(this.tbstokfisidetayi).done((values,newId)=>{
          resolve(Math.floor(Math.random() * 10)); // 0 ve 9 arasında sayı üretir.
          //resolve(newId);
        //})




      })
    },
    tbstokfisimaster_ekle(lFisNo){
      return new Promise((resolve,reject) => {
        this.sMail="";
        this.sSatir="";
        this.sFirma ="";
        this.sModuller ="";
        this.sOnayKodlari ="";
        var nStokFisiID=0;
        this.tbstokfisimaster.nFirmaID=this.getFirmaResult.nFirmaID;
        this.tbstokfisimaster.lFisNo = lFisNo;
        this.tbstokfisimaster.sFisTipi ="FS";
        this.tbstokfisimaster.nGirisCikis =3;
        var currentDate = new Date();
        this.tbstokfisimaster.dteFisTarihi = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        this.tbstokfisimaster.dteValorTarihi = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        this.tbstokfisimaster.lToplamMiktar = this.getTradeResult.lToplamMiktar;
        this.tbstokfisimaster.lKdv1 = this.getTradeResult.lToplamKdv;
        this.tbstokfisimaster.lMalBedeli = this.getTradeResult.lMalBedeli;
        this.tbstokfisimaster.lMalIskontoTutari= this.getTradeResult.lMalIskontoTutari;
        this.tbstokfisimaster.lNetTutar = this.getTradeResult.lNetTutar;
        this.tbstokfisimaster.sYaziIle = this.cevir(this.tbstokfisimaster.lNetTutar);
        // this.tbstokfisimaster.sCariIslem =null;
        // this.tbstokfisimaster.sCariKdvIslem=null;
        // this.tbstokfisimaster.nKasaIslemID=null;
        // this.tbstokfisimaster.lTransferFisiID=null;
        // this.tbstokfisimaster.nTransferFirmaID=null;
        // this.tbstokfisimaster.nCariHareketID=null;
        // this.tbstokfisimaster.nCariKdvHareketID=null;
        //console.log(this.tbstokfisimaster);
        //mobilit.tbStokFisiMaster.insert(this.tbstokfisimaster).done((values,newId)=>{
          let newId = Math.floor(Math.random() * 100); // 0 ve 99 arasında sayı üretir.
          nStokFisiID = newId;
          console.log('tbstokfisimaster eklendi'+ newId);
          this.tbstokfisiaciklamasi.nStokFisiID= newId;
          this.tbstokfisiaciklamasi.sAciklama1= this.sevkiyat.sFirmaAdi;
          //console.log(this.tbstokfisiaciklamasi);
          //mobilit.tbStokFisiAciklamasi.insert(this.tbstokfisiaciklamasi).done(console.log('stokfisiaciklamasi eklendi'));
          this.tbfirmahareketi.nFirmaID = this.tbstokfisimaster.nFirmaID;
          this.tbfirmahareketi.sCariIslem = this.tbstokfisimaster.sFisTipi;
          this.tbfirmahareketi.dteIslemTarihi = this.tbstokfisimaster.dteFisTarihi;
          this.tbfirmahareketi.dteValorTarihi = this.tbstokfisimaster.dteValorTarihi;
          this.tbfirmahareketi.sEvrakNo = lFisNo.toString();
          this.tbfirmahareketi.lBorcTutar = this.tbstokfisimaster.lNetTutar;
          this.tbfirmahareketi.lAlacakTutar = 0;
          //console.log(this.tbfirmahareketi);
          //mobilit.tbFirmaHareketi.insert(this.tbfirmahareketi).done((values,newId)=>{
            console.log('tbfirmahareketi eklendi'+ newId)
            this.tbstokfisiodemeplani.nStokFisiID= nStokFisiID;
            this.tbstokfisiodemeplani.nCariHareketID= newId;
            this.tbstokfisiodemeplani.lTutari= this.tbstokfisimaster.lNetTutar;
            //console.log(this.tbstokfisiodemeplani);
            //mobilit.tbStokFisiOdemePlani.insert(this.tbstokfisiodemeplani).done(console.log('tbstokfisiodemeplani eklendi'));
            this.tbstokfisipesinadre.nStokFisiID= nStokFisiID;
            this.tbstokfisipesinadre.sAciklama = this.sevkiyat.sFirmaAdi;
            this.tbstokfisipesinadre.sAdres1 = this.sevkiyat.sAdres1;
            this.tbstokfisipesinadre.sAdres2 = this.sevkiyat.sAdres2;
            this.tbstokfisipesinadre.sSemt = this.sevkiyat.sSemt;
            this.tbstokfisipesinadre.sIl = this.sevkiyat.sIl;
            this.tbstokfisipesinadre.sVergiDairesi= this.sevkiyat.sVergiDairesi;
            this.tbstokfisipesinadre.sVergiNo = this.sevkiyat.sVergiNo;
            this.tbstokfisipesinadre.sPostaKodu = this.sevkiyat.sMail;
            this.tbstokfisipesinadre.sTelefon1 = this.sevkiyat.sTelefon;
            //console.log(this.tbstokfisipesinadre);
            //mobilit.tbStokFisiPesinAdres.insert(this.tbstokfisipesinadre).done(
              console.log('tbstokfisipesinadres eklendi'),
            this.sMail ="<html><head><title>ceddan.com Musteri Hizmetleri</title></head><body>",
            this.sMail += "<div align='center'>",
            this.sMail += "<table style='background-color:#425967;padding:10px;width:620px;text-align:left;' width='620' cellpadding='0' cellspacing='0'>",
            this.sMail += "<tbody><tr><td>",
            this.sMail += "<table width='100%' style='background-color:#FFFFFF;' cellpadding='0' cellspacing='0'>",
            this.sMail += "<tbody><tr>",
            this.sMail += "<td style='padding:10px;'><a href='https://www.ceddan.com/' target='_blank'><img src='https://www.ceddan.com/logo.png' border='0'></a></td>",
            this.sMail += "<td style='color:#1A2640;font-family:&#39;Trebuchet MS&#39;;font-size:14px;' align='right'>+90 312 527 4 888<span style='color:#A5B9C5;font-size:24px;'>|</span> <a href='https://www.ceddan.com' style='text-decoration:none;color:#1A2640;' target='_blank'>www.ceddan.com</a>&nbsp;&nbsp;&nbsp;</td>",
            this.sMail += "</tr>",
            this.sMail += "<tr>",
            //'sMail += "<td colspan='2'><img src='https://www.ceddan.com/images/loader.gif'></td>"
            this.sMail += "<td colspan='2'></td>",
            this.sMail += "</tr>",
            this.sMail += "<tr>",
            this.sMail += "<td style='padding:10px;font-size:12px;font-family:&#39;Arial&#39;;' colspan='2'>",
            this.sMail += "<p style=''>"+this.$t('sayin')+"<strong>" + this.sevkiyat.sFirmaAdi+ "</strong>,</p>",
            this.sMail += "<p style=''>" + new Date().toLocaleString() + " tarihinde, " + window.location.host + " IP adresinden Sipariş Talebinde bulundunuz. Sipariş içeriğiniz aşağıda sunulmuştur: <br><br>",

            this.sMail += "<h4>"+this.$t('siparis')+"</h4>",

            this.sMail += "<table class='table cart' Width='100%'>",
            this.sMail += "<tbody>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td><strong>"+this.$t('sayin')+"</strong></td>",
            this.sMail += "<td>" + this.getFirmaResult.sKodu + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td><strong>"+ this.$t('aciklama') + "</strong></td>",
            this.sMail += "<td> " + this.sevkiyat.sFirmaAdi+ "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td><strong>" + this.$t('kayitno') + "</strong></td>",
            this.sMail += "<td>" + nStokFisiID + "</td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td><strong>"+ this.$t('adres') +"</strong></td>",
            this.sMail += "<td>" + this.sevkiyat.sAdres1 + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td><strong>"+ this.$t('tarih') +"</strong></td>",
            this.sMail += "<td>" + this.tbstokfisimaster.dteFisTarihi.toLocaleString() + "</td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td></td>",
            this.sMail += "<td>" + this.sevkiyat.sAdres2 + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td><strong>"+ this.$t('fisno') +"</strong></td>",
            this.sMail += "<td>" + this.tbstokfisimaster.lFisNo + "</td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td></td>",
            this.sMail += "<td>" + this.sevkiyat.sSemt + " | " + this.sevkiyat.sIl + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td><strong>"+this.$t('odeme')+"</strong></td>",
            
            this.sMail += "<td>" + this.$t('kapidaodeme') + "</td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td></td>",
            this.sMail += "<td>" + this.sevkiyat.sTelefon + "|" + this.sevkiyat.sMail + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "</tr>",

            this.sMail += "<tr class='cart_item' Width='100%'>",
            this.sMail += "<td></td>",
            this.sMail += "<td>"+this.$t('vergidairesi')+": " + this.sevkiyat.sVergiDairesi + "  " + this.$t('vkn') +": " + this.sevkiyat.sVergiNo + "</td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "<td></td>",
            this.sMail += "</tr>",

            this.sMail += "</tbody>",
            this.sMail += "</table>",
            this.sMail += "<p></p>",
            this.sMail += "<p></p>",

            this.sSatir += "<table class='table cart' Width='100%'>",
            this.sSatir += "<thead>",
            this.sSatir += "<tr>",
            this.sSatir += "<th class='cart-product-thumbnail'>&nbsp;</th>",
            this.sSatir += "<th class='cart-product-name'>"+this.$t('aciklama')+"</th>",
            this.sSatir += "<th class='cart-product-quantity'>"+this.$t('miktar')+"</th>",
            this.sSatir += "<th class='cart-product-price'>"+this.$t('fiyat')+"</th>",
            this.sSatir += "<th class='cart-product-subtotal'>"+this.$t('tutar')+"</th>",
            this.sSatir += "</tr>",
            this.sSatir += "</thead>",
            this.sSatir += "<tbody>",

            //this.sMail += sSatir,

            this.sFirma = "<p>"+this.$t('sevkiyatadresi')+";</p>",
            this.sFirma += "<p>",
            this.sFirma += "<strong>"+this.$t('kayitno')+":</strong> " + this.sevkiyat.nFirmaID + "<br>",
            this.sFirma += "<strong>"+this.$t('aciklama')+":</strong> " + this.sevkiyat.sFirmaAdi + "<br>",
            this.sFirma += "<strong>"+this.$t('adres')+":</strong> " + this.sevkiyat.sAdres1 + "<br>",
            this.sFirma += "<strong>"+this.$t('adres')+":</strong> " + this.sevkiyat.sAdres2 + "<br>",
            this.sFirma += "<strong>"+this.$t('il')+":</strong> " + this.sevkiyat.sIl + "<br>",
            this.sFirma += "<strong>"+this.$t('sehir')+":</strong> " + this.sevkiyat.sSemt+ "<br>",
            this.sFirma += "<strong>"+this.$t('vergidairesi')+":</strong> " + this.sevkiyat.sVergiDairesi + "<br>",
            this.sFirma += "<strong>"+this.$t('vkn')+":</strong> " + this.sevkiyat.sVergiNo + "<br>",
            this.sFirma += "<strong>"+this.$t('email')+":</strong> " + this.sevkiyat.sMail + "<br>",
            this.sFirma += "<strong>"+this.$t('telefon')+":</strong> " + this.sevkiyat.sTelefon + "<br>",
            this.sFirma += "<strong>"+this.$t('yetkili')+":</strong> " + this.sevkiyat.sYetkili + "<br>",
            this.sFirma += "<strong>"+this.$t('sektor')+":</strong> " + this.sevkiyat.sSektor + "<br>",
            this.sFirma += "<strong>"+this.$t('notlar')+":</strong> " + this.sevkiyat.sNot + "<br>",
            this.sFirma += "</p>",
              resolve(nStokFisiID)
              //);


          //})
          
        //})

      })


    },
    cevir(tutar) {
        var sayi = tutar.toString()
        var sayarr = sayi.split(".");
        var eleman = 0;
        var basamak = 0;
        var basamakd = 0;
        var str = "";
        var items = [
            ["", ""],
            ["Bir", "ON"],
            ["Iki", "YIRMI"],
            ["Uc", "OTUZ"],
            ["Dort", "KIRK"],
            ["Bes", "ELLI"],
            ["Alti", "ALTMIS"],
            ["Yedi", "YETMIS"],
            ["Sekiz", "SEKSEN"],
            ["Dokuz", "DOKSAN"]
        ];
        for (eleman = 0; eleman < sayarr.length; eleman++) {
            for (basamak = 1; basamak <= sayarr[eleman].length; basamak++) {
                basamakd = 1 + (sayarr[eleman].length - basamak);
                try {
                    switch (basamakd) {
                        case 6:
                            str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0] + " YUZ";
                            break;
                        case 5:
                            str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][1];
                            break;
                        case 4:
                            if (items[sayarr[eleman].charAt(basamak - 1)][0] != "BIR") str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0] + " BIN";
                            else str = str + " BIN";
                            break;
                        case 3:
                            if (items[sayarr[eleman].charAt(basamak - 1)][0] == "") {
                                str = str + "";

                            }
                            else if (items[sayarr[eleman].charAt(basamak - 1)][0] != "BIR") str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0] + " YUZ";
                            else str = str + " YUZ";
                            break;
                        case 2:
                            str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][1];
                            break;
                        default:
                            str = str + " " + items[sayarr[eleman].charAt(basamak - 1)][0];
                            break;
                    }
                } catch (err) {
                    //alert(err.description);
                    //alert("eleman" + basamak);
                    break;
                }
            }
            //alert(eleman);
            if (eleman == 0) {
                str = str + " TL"
            }
            else {
                if (sayarr[1] != "00") str = str + " Krs";
            }
            //alert(str);
        }
        return str;
    },
    kontrol(){
      
      return new Promise((resolve,reject) => {
      let sUyari ='';
      
      if (this.getTradeResult.bFirma==false && this.getTradeResult.bLisans ==false){
        if (this.sevkiyat.sFirmaAdi ==''){sUyari+= '[Müşteri Adı] Boş Bırakamazsınız' + '\r\n'};
        if (this.sevkiyat.sAdres1 ==''){sUyari+='[Adres] Boş Bırakamazsınız'+ '\r\n'};
        if (this.sevkiyat.sSemt ==''){sUyari+='[Semt] Boş Bırakamazsınız'+ '\r\n'};
        if (this.sevkiyat.sIl ==''){sUyari+='[İl] Boş Bırakamazsınız'+ '\r\n'};
        if (this.sevkiyat.sVergiDairesi ==''){sUyari+='[Vergi Dairesi] Boş Bırakamazsınız'+ '\r\n'};
        if (this.sevkiyat.sVergiNo ==''){sUyari+='[Vergi Numarası] Boş Bırakamazsınız'+ '\r\n'};
        if (this.sevkiyat.sMail ==''){sUyari+='[Mail] Boş Bırakamazsınız'+ '\r\n'};
        if (this.sevkiyat.sTelefon ==''){sUyari+='[Telefon] Boş Bırakamazsınız'+ '\r\n'};
        if (this.sevkiyat.sYetkili ==''){sUyari+='[Yetkili] Boş Bırakamazsınız'+ '\r\n'};
        if (this.sevkiyat.sSektor ==''){sUyari+='[Sektör] Boş Bırakamazsınız'+ '\r\n'};
      };
      if (this.getTradeResult.bLisans ==true && this.selectedRowKeys.length==0){sUyari+='Lisans seçmelisiniz'+ '\r\n'};
      
      if (sUyari !== ''){
        notify(sUyari, "warning", 5000);
        //this.$refs.checkoutWizard.prevTab();
        resolve(false);
      }else {resolve(true)}
      });


    },
    // TAB 3
    makePayment() {
      let result = confirm("<i>"+this.$t('eminmisiniz')+"</i>", this.$t('bitir'));
            result.then((dialogResult) => {
                //alert(dialogResult ? "Confirmed" : "Canceled");
                if (dialogResult){
                  this.loadingVisible=true;
                  this.tamamla();
                }
            });
    },
    getBookName (xmlDoc) {
      var x, i, txt;
      console.log(xmlDoc);
      // xmlDoc = xml.responseXML;
      txt = "";
      x = xmlDoc.documentElement.childNodes;
      for (i = 0; i < x.length; i++) {
          if (x[i].nodeType == 1) {
              txt += x[i].nodeName + "\r\n";
          }
      }
      return txt;
    },
    tamamla(){
      return new Promise((resolve,reject) => {
        let arr = this.$store.state.eCommerce.cartItems.slice();
        var self = this;
        self.kontrol().then(bDevam =>{
        if (bDevam==true){
        self.sorgu_lFisNo().then(lFisNo=>{
          self.tbstokfisimaster_ekle(lFisNo).then(nStokFisiID=>{
            console.log('fis eklendi'+nStokFisiID)
            arr.forEach(async (itm,index) =>{
              self.sorgu_nStokID(itm.sKodu,itm.sAciklama).then(nStokID=>{
                console.log('StokID'+ nStokID);
                var lBrutFiyat =0;
                var lBrutTutar =0;
                var lDovizFiyat = 0;
                var lDovizKuru = this.getTradeResult.lDovizKuru;
                var sDovizCinsi =''
                if (itm.sDovizCinsi ==='USD') {
                  sDovizCinsi = itm.sDovizCinsi;
                  lBrutFiyat = itm.lFiyat1 * lDovizKuru;
                  lBrutTutar = lBrutFiyat * itm.quantity
                }else {
                  sDovizCinsi='';
                  lBrutFiyat = itm.lFiyat1
                  lBrutTutar = lBrutFiyat * itm.quantity
                };
                self.tbstokfisidetayi_ekle(nStokFisiID,nStokID,itm.sAciklama,itm.quantity,itm.nKdvOrani,lBrutFiyat,lBrutTutar,sDovizCinsi,itm.lFiyat1).then(nIslemID =>{
                  console.log('satir eklendi'+ nIslemID);
                  setTimeout(function () {
                  const result = self.satir_islem(itm,index).then(satirresult=>{
                    console.log('satir bitti' + satirresult);
                    self.sSatir += "<tr class='cart_item' Width='100%'>"
                    self.sSatir += "<td class='cart-product-thumbnail'>"
                    self.sSatir += "<a href='https://www.ceddan.com/urun/" + itm.nStokID + "/" + itm.sSinifKodu + "/" + itm.sAciklama + "' class='item-quick-view' data-lightbox='ajax'><img width='64' height='64' src="+window.location.origin+itm.pResim  +" alt='" + itm.sAciklama + "'></a>"
                    self.sSatir += "</td>"

                    self.sSatir += "<td class='cart-product-name'>"
                    self.sSatir += "<a href='https://www.ceddan.com/urun/" + itm.nStokID + "/" + itm.sSinifKodu + "/" + itm.sAciklama + "' class='item-quick-view' data-lightbox='ajax'>" + itm.sAciklama + "</a>"
                    self.sSatir += "</td>"

                    self.sSatir += "<td class='cart-product-quantity'>"
                    self.sSatir += "<div class='quantity clearfix'>"
                    self.sSatir += "" + itm.quantity + ""
                    self.sSatir += "</div>"
                    self.sSatir += "</td>"

                    self.sSatir += "<td class='cart-product-price'>"
                    self.sSatir += "<div class='price clearfix'>"
                    self.sSatir += "" + lBrutFiyat + ""
                    self.sSatir += "</div>"
                    self.sSatir += "</td>"

                    self.sSatir += "<td class='cart-product-subtotal'>"
                    self.sSatir += "<span class='amount'>" + lBrutTutar + "</span>"
                    self.sSatir += "</td>"
                    self.sSatir += "</tr>"
                    self.removeItemFromCart(itm);
                    console.log(result)
                    if(index == arr.length-1){
                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "<span class='amount'>" + self.sevkiyat.sNot + "</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='notopborder cart-product-name'>"
                      self.sSatir += ""
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='notopborder cart-product-name'>"
                      self.sSatir += "<span class='amount'><asp:label id='lbl_Toplam'  Text='" + self.tbstokfisimaster.lMalBedeli + "'></asp:label>=========</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='notopborder cart-product-name'>"
                      self.sSatir += "<strong>"+self.$t('toplam')+"</strong>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='notopborder cart-product-name'>"
                      self.sSatir += "<span class='amount'><asp:label id='lblToplam'  Text='" + self.tbstokfisimaster.lMalBedeli + "'></asp:label>" + self.tbstokfisimaster.lMalBedeli  + "</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<strong>"+self.$t('kdv')+"</strong>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<span class='amount'><asp:label id='lblKdv'  Text='" + self.tbstokfisimaster.lKdv1  + "'></asp:label>" + self.tbstokfisimaster.lKdv1  + "</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<strong>"+self.$t('kargoucreti')+"</strong>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<span class='amount'><asp:label id='lblKargo'  Text='" + 'Kargo' + "'></asp:label>" + self.$t('ucretsiz') + "</span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sSatir += "<tr class='cart_item' Width='100%'>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<strong>"+self.$t('yekun')+"</strong>"
                      self.sSatir += "</td>"
                      self.sSatir += "<td class='cart-product-name'>"
                      self.sSatir += "<span class='amount color lead'><strong><asp:label id='lblNetTutar'  Text='" + self.tbstokfisimaster.lNetTutar   + "'></asp:label>" + self.tbstokfisimaster.lNetTutar   + "</strong></span>"
                      self.sSatir += "</td>"
                      self.sSatir += "</tr>"

                      self.sMail += self.sSatir

                      self.sMail += "</tbody>"
                      self.sMail += "</table>"
                      //'sMail += "</div>"
                      self.sMail += "<div>" + self.sFirma + "</div>"
                      self.sMail += "<p><strong>" + self.sModuller + "</strong></p>"
                      self.sMail += "<div>" + self.sOnayKodlari + "</div>"
                      self.sMail += "</p>"
                      self.sMail += "<p style=''>Her türlü sorunuzda bize <a href='mailto:destek@ceddan.com' style='color:#000000;'>destek@ceddan.com</a> adresinden ulaşabilir veya <a href='tel:+903125274888'>(+90) 312 527 4 888</a> nolu telefondan müşteri hizmetleri ile görüşebilirsiniz.</p>"
                      self.sMail += "<p style=''>Saygılarımızla,</p>"
                      self.sMail += "<p style=''>Ceddan ® Müşteri Hizmetleri</p>"
                      self.sMail += "</td>"
                      self.sMail += "</tr>"
                      self.sMail += "<tr>"
                      //'sMail += "<td colspan='2'><img src='https://www.ceddan.com/images/loader.gif'></td>"
                      self.sMail += "<td colspan='2'></td>"
                      self.sMail += "</tr>"
                      self.sMail += "<tr>"
                      self.sMail += "<td style='padding:10px;color:#808080;font-family:&#39;Trebuchet MS&#39;;font-size:12px;' colspan='2'>"
                      self.sMail += "<p style=''>Copyright © " +  new Date().getFullYear()  + "  ceddan.com Tüm hakları saklıdır.</p>"
                      self.sMail += "<p style=''><a href='https://www.ceddan.com/' style='text-decoration:none;color:#1A2640;' target='_blank'>www.ceddan.com</a></p>"
                      self.sMail += "</td>"
                      self.sMail += "</tr>"
                      self.sMail += "</tbody></table>"
                      self.sMail += "</td></tr>"
                      self.sMail += "</tbody></table>"
                      self.sMail += "<br>"
                      self.sMail += "</table></body></html>";
                      console.log('tamamlandı');
                      localStorage.setItem("sMail",self.sMail);
                      var str="<?xml version='1.0' encoding='utf-8'?>"+
                      "<soap12:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap12='http://www.w3.org/2003/05/soap-envelope'>"+
                      "<soap12:Header>" +
                      "<SecuredTokenWebservice xmlns='http://tempuri.org/'>"+
                      "<sMail>"+self.sevkiyat.sMail+"</sMail>"+
                      "<sUserName>"+self.getFirmaResult.nFirmaID+"</sUserName>"+
                      "<sPassword>"+self.getFirmaResult.sPostaKodu+"</sPassword>"+
                      "<AuthenticationToken>string</AuthenticationToken>"+
                      "<sBayii>"+self.getFirmaResult.nFirmaID+"</sBayii>"+
                      "</SecuredTokenWebservice>"+
                      "</soap12:Header>"+
                      "<soap12:Body>"+
                      "<AuthenticationUser xmlns='http://tempuri.org/' />"+
                      "</soap12:Body>"+
                      "</soap12:Envelope>";
                      
                      axios({method:'post',url:'https://www.barkodyazilimevi.com.tr/AuthToken.asmx',data:str,headers: {'Content-Type': 'text/xml','Access-Control-Allow-Headers': 'Origin'}})
                      .then((response) => {
                        console.log(response);
                        var parseString = require('xml2js').parseString;
                        var xml = response.data
                        var AuthenticationToken =""
                        parseString(xml, function (err, result) {
                          console.log(result["soap:Envelope"]["soap:Body"][0].AuthenticationUserResponse[0].AuthenticationUserResult[0]);
                          AuthenticationToken = result["soap:Envelope"]["soap:Body"][0].AuthenticationUserResponse[0].AuthenticationUserResult[0];
                        });
                        //self.sFirma = self.sSatir+ self.sFirma;
                      
                      var str2="<?xml version='1.0' encoding='utf-8'?>"+
                      "<soap12:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap12='http://www.w3.org/2003/05/soap-envelope'>"+
                      "<soap12:Header>" +
                      "<SecuredTokenWebservice xmlns='http://tempuri.org/'>"+
                      "<sMail>"+self.getFirmaResult.sMail+"</sMail>"+
                      "<sUserName>"+self.getFirmaResult.nFirmaID+"</sUserName>"+
                      "<sPassword>"+self.getFirmaResult.sPostaKodu+"</sPassword>"+
                      "<AuthenticationToken>"+AuthenticationToken+"</AuthenticationToken>"+
                      "<sBayii>"+self.getFirmaResult.nFirmaID+"</sBayii>"+
                      "</SecuredTokenWebservice>"+
                      "</soap12:Header>"+
                      "<soap12:Body>"+
                      "<mail_gonder xmlns='http://tempuri.org/'>"+
                      "<sSubject>Sipariş</sSubject>"+
                      "<nFirmaID>"+self.getFirmaResult.nFirmaID+"</nFirmaID>"+
                      "<sKodu>"+self.getFirmaResult.sYetkili+"</sKodu>"+
                      "<sAciklama>"+self.sevkiyat.sFirmaAdi+"</sAciklama>"+
                      "<sAdres1>"+self.sevkiyat.sAdres1+"</sAdres1>"+
                      "<sAdres2>"+self.sevkiyat.sAdres2+"</sAdres2>"+
                      "<sSemt>"+self.sevkiyat.sSemt+"</sSemt>"+
                      "<sIl>"+self.sevkiyat.sIl+"</sIl>"+
                      "<telefon>"+self.sevkiyat.sTelefon+"</telefon>"+
                      "<sEMail>"+self.sevkiyat.sMail+"</sEMail>"+
                      "<sVergiDairesi>"+self.sevkiyat.sVergiDairesi+"</sVergiDairesi>"+
                      "<sVergiNo>"+self.sevkiyat.sVergiNo+"</sVergiNo>"+
                      "<nAlisverisID>"+self.tbstokfisiodemeplani.nStokFisiID+"</nAlisverisID>"+
                      "<lFisNo>"+self.tbstokfisimaster.lFisNo+"</lFisNo>"+
                      "<sFirma><![CDATA["+  self.sSatir + self.sFirma+ "]]></sFirma>"+
                      "<sModuller><![CDATA[<strong>" + self.sModuller + "</strong>]]></sModuller>"+
                      "<sOnayKodlari><![CDATA["+ self.sOnayKodlari+"]]></sOnayKodlari>"+
                      "<sOdemeSekli>Kapıda Ödeme</sOdemeSekli>"+
                      "<sIp>"+self.sIp+"</sIp>"+
                      "</mail_gonder>"+
                      "</soap12:Body>"+
                      "</soap12:Envelope>";
                      //console.dir(str2);
                      axios({method:'post',url:'https://www.barkodyazilimevi.com.tr/AuthToken.asmx',data:str2,headers: {'Content-Type': 'text/xml; charset=utf-8','Access-Control-Allow-Headers': 'Origin'}})
                      .then((response) => {
                        console.log(response); 
                      })

                        //console.log(response); 
                      
                      })
                      .catch((error) => {console.log(error)});
                      };
                      router.push({ name: 'blank'});
                      self.$refs.checkoutWizard.prevTab();
                      self.$refs.checkoutWizard.prevTab();
                      self.loadingVisible=false;
                      notify(self.$t('tamamlandi'), "success", 5000);
                      
                    

                    });
                  }, 500 + (index * 500));
                  })
              })
            })
            })
          });

        }
        })
      // arr.forEach(async (itm,index) =>{
        
      // setTimeout(function () {
      //  //console.log(itm.sKodu);
      //  const result = self.satir_islem(itm,index).then(result=>{console.log(result)});
      //  //console.log(itm.sKodu + result)
      //  }, 500 + (index * 500));
       
        
      // });
      
        // this.$validator.validateAll("cvv-form").then(result => {
        //   if (result) {
        //     // if form have no errors
        //     this.$vs.notify({
        //       title: "Success",
        //       text: "Payment received successfully",
        //       color: "success",
        //       iconPack: "feather",
        //       icon: "icon-check"
        //     });
        //   } else {
        //     this.$vs.notify({
        //       title: "Error",
        //       text: "Please enter valid details",
        //       color: "warning",
        //       iconPack: "feather",
        //       icon: "icon-alert-circle"
        //     });
        //   }
        // });

      });
    }
  },
    components: {
      vscript,
        //CardForm,
        ItemListView,
        FormWizard,
        TabContent,
        DxLookup,
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxFilterRow,
    DxSelectBox,
    DxTextBox,
    DxTextArea,
    DxLoadPanel
    }
}
</script>

<style lang="scss" scoped>
#ecommerce-checkout-demo {
    .item-view-primary-action-btn {
        color: #2c2c2c !important;
        background-color: #f6f6f6;
    }

    .item-name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
    }

    .vue-form-wizard {
        /deep/ .wizard-header {
            padding: 0;
        }

        /deep/ .wizard-tab-content {
            padding-right: 0;
            padding-left: 0;
        }
    }
}
</style>
